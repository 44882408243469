// export const data = [
//   {
//     name: "Mar",
//     individuals: 80,
//     organisations: 20,
//   },
//   {
//     name: "Apr",
//     individuals: 100,
//     organisations: 110,
//   },
//   {
//     name: "May",
//     individuals: 60,
//     organisations: 45,
//   },
//   {
//     name: "June",
//     individuals: 75,
//     organisations: 75,
//   },
//   {
//     name: "July",
//     individuals: 100,
//     organisations: 50,
//   },
//   {
//     name: "Aug",
//     individuals: 40,
//     organisations: 24,
//   },
//   {
//     name: "Sept",
//     individuals: 120,
//     organisations: 130,
//   },
//   {
//     name: "Oct",
//     individuals: 70,
//     organisations: 50,
//   },
//   {
//     name: "Nov",
//     individuals: 50,
//     organisations: 70,
//   },
// ];

// export const tooltipLabels = [
//   {
//     key: "individuals",
//     label: "Individuals",
//   },
//   {
//     key: "organisations",
//     label: "Companies",
//   },
// ];

export const ind_vs_org_data = [
  {
    name: "Mon - Aug 19",
    individual: 45,
    company: 120,
    individual_percentage: 4,
    company_percentage: 96,
  },
  {
    name: "Tue - Aug 20",
    individual: 45,
    company: 125,
    individual_percentage: 33,
    company_percentage: 67,
  },
  {
    name: "Wed - Aug 21",
    individual: 45,
    company: 186,
    individual_percentage: 33,
    company_percentage: 67,
  },
  {
    name: "Thu - Aug 22",
    individual: 45,
    company: 164,
    individual_percentage: 33,
    company_percentage: 67,
  },
  {
    name: "Fri - Aug 23",
    individual: 45,
    company: 204,
    individual_percentage: 33,
    company_percentage: 67,
  },
  {
    name: "Sat - Aug 24",
    individual: 45,
    company: 503,
    individual_percentage: 33,
    company_percentage: 67,
  },
  {
    name: "Sun - Aug 25",
    individual: 45,
    company: 451,
    individual_percentage: 33,
    company_percentage: 67,
  },
];

export const tooltipLabels = [
  {
    key: "individual_percentage",
    label: "Individuals",
  },
  {
    key: "company_percentage",
    label: "Companies",
  },
];
