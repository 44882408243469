import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as styles from "./styles.module.scss";
import {
  getIndividuals,
  getOrganisations,
} from "../../../state/actions/individualsVsOrganisations.actions";
import CalendarPicker from "../../atoms/CalendarPicker";
import {
  ind_vs_org_data,
  tooltipLabels,
} from "../../../constants/userTypesData";
import Spinner from "../../atoms/Spinner";
import CustomBarChart from "../../atoms/BarChart";

const UserTypes = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  const dispatch = useDispatch();

  const [, setAllIndividuals] = useState(null);
  const [, setAllOrganisations] = useState(null);

  useEffect(() => {
    const allIndividuals = () => {
      let individuals = async () => await dispatch(getIndividuals());
      setAllIndividuals(individuals);
    };
    allIndividuals();
  }, []);

  useEffect(() => {
    const allOrganisations = () => {
      let organisations = async () => await dispatch(getOrganisations());
      setAllOrganisations(organisations);
    };
    allOrganisations();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>User Types </h2>
        <div className={styles.legend}>
          <span>Individuals</span>
          <span>Companies</span>
        </div>
        <div className={styles.dropDowns}>
          <CalendarPicker component={"ind_vs_org"} />
        </div>
      </div>
      <h3>Comparision of individual users and organisations</h3>
      {ind_vs_org_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      {ind_vs_org_data.length > 0 &&
        ind_vs_org_data.map((i) => i !== undefined) && (
          <CustomBarChart
            data={ind_vs_org_data}
            tooltipLabels={tooltipLabels}
            isMobile={isMobile}
          />
        )}
    </div>
  );
};

export default UserTypes;
