import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "../RadialSeparators";
import "react-circular-progressbar/dist/styles.css";
import * as styles from "./styles.module.scss";

const PercentageCircle = ({ percentage, pathColor, trailColor }) => {
  return (
    <div className={styles.container}>
      <CircularProgressbarWithChildren
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={7}
        styles={buildStyles({
          strokeLinecap: "butt",
          textSize: "13px",
          rotation: 0.5,
          textColor: "white",
          pathColor: pathColor,
          trailColor: trailColor,
        })}
      >
        <RadialSeparators
          count={50}
          style={{
            background: `${trailColor}`,
            width: "2px",
            // This needs to be equal to props.strokeWidth
            height: `${7}%`,
          }}
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default PercentageCircle;
