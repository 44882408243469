import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import { data } from "../../../constants/countryDistribution";
import CountriesDataTable from "../../molecules/CountriesDataTable";

const CountriesTable = () => {
  const [countries_data] = useState([...data]);
  return (
    <div className={styles.container}>
      <CountriesDataTable data={countries_data} rowsPerPage={8} />
    </div>
  );
};

export default CountriesTable;
