import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import CalendarContainer from "../../molecules/CalendarContainer";
import dropDownIcon from "../../../assets/images/icons/drop-down.svg";
import { generateCalendarData } from "../../../helpers/calendarOptions";

const CalendarPicker = ({ component, onSelectedOptionChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [displayCalendar, setDisplayCalendar] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const isLarge = width >= 768;
  const calendarOptions = generateCalendarData();

  const handleChange = (isOpen, displayCalendar) => {
    setIsOpen(isOpen);
    setDisplayCalendar(displayCalendar);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => {
    if (value !== "Custom Range") {
      setSelectedOption(value);
      setIsOpen(false);
      onSelectedOptionChange(value);
    } else {
      setSelectedOption(value);
      setDisplayCalendar(true);
      onSelectedOptionChange("Custom Range");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div
      className={styles.container}
      style={
        component === "AverageActiveDailyUsers"
          ? { padding: "0 125px 0 0" }
          : null
      }
    >
      {component === "Gender Distribution" ||
      component === "Social Page Stats" ||
      component === "PackagesBoughtByClients" ||
      !isLarge ? (
        <div className={styles.selectShortFormat} onClick={toggling}>
          <i className={`fa fa-calendar `}></i>{" "}
          {selectedOption === null ? calendarOptions[1][1] : selectedOption}{" "}
          <img src={dropDownIcon} alt="dropdown-icon" />
        </div>
      ) : (
        <div className={styles.select} onClick={toggling}>
          <i className={`fa fa-calendar `}></i>{" "}
          {selectedOption === null ? calendarOptions[0][1] : selectedOption}{" "}
          <img src={dropDownIcon} alt="dropdown-icon" />
        </div>
      )}

      {component === "Gender Distribution" ||
      component === "Social Page Stats" ||
      component === "PackagesBoughtByClients"
        ? isLarge &&
          isOpen && (
            <div>
              <div className={styles.options}>
                {calendarOptions[1].map((item, index) => (
                  <p
                    key={index}
                    className={styles.item}
                    onClick={() => onOptionClicked(item)}
                    value={item["name"]}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          )
        : isLarge &&
          isOpen && (
            <div>
              <div className={styles.options}>
                {calendarOptions[0].map((item, index) => (
                  <p
                    key={index}
                    className={styles.item}
                    onClick={() => onOptionClicked(item)}
                    value={item["name"]}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          )}
      {isOpen && displayCalendar && (
        <div className={styles.calendar}>
          <CalendarContainer onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default CalendarPicker;
