export const browserEngagements = [
  {
    browser: "Chrome",
    engagement: "chromeEngagements",
  },
  {
    browser: "Firefox",
    engagement: "firefoxEngagements",
  },
  {
    browser: "Opera",
    engagement: "operaEngagements",
  },
  {
    browser: "Edge",
    engagement: "edgeEngagements",
  },
  {
    browser: "Safari",
    engagement: "safariEngagements",
  },
];
