export const tooltipLabels = [
  {
    key: "referral_percentage",
    label: "Referral",
  },
  {
    key: "direct_percentage",
    label: "Direct",
  },
  {
    key: "organic_search_percentage",
    label: "Organic Search",
  },
];

export const new_users_channel_data = [
  {
    name: "Mon - Oct 20",
    direct: 801,
    organic_search: 397,
    referral: 500,
    direct_percentage: 47,
    organic_search_percentage: 20,
    referral_percentage: 33,
  },
  {
    name: "Tue - Oct 21",
    direct: 4010,
    organic_search: 710,
    referral: 150,
    direct_percentage: 82,
    organic_search_percentage: 15,
    referral_percentage: 3,
  },
  {
    name: "Wed - Oct 22",
    direct: 190,
    organic_search: 2701,
    referral: 850,
    direct_percentage: 5,
    organic_search_percentage: 72,
    referral_percentage: 23,
  },
  {
    name: "Thu - Oct 23",
    direct: 7701,
    organic_search: 8000,
    referral: 650,
    direct_percentage: 47,
    organic_search_percentage: 49,
    referral_percentage: 4,
  },
  {
    name: "Fri - Oct 24",
    direct: 5006,
    organic_search: 100,
    referral: 320,
    direct_percentage: 92,
    organic_search_percentage: 2,
    referral_percentage: 6,
  },
  {
    name: "Sat - Oct 25",
    direct: 201,
    organic_search: 783,
    referral: 50,
    direct_percentage: 19,
    organic_search_percentage: 75,
    referral_percentage: 4,
  },
  {
    name: "Sun - Oct 26",
    direct: 781,
    organic_search: 5000,
    referral: 770,
    direct_percentage: 12,
    organic_search_percentage: 76,
    referral_percentage: 12,
  },
];
