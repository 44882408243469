import React, { useState } from "react";
import * as styles from "./styles.module.scss";

const VerticalProgressBar = ({ percentage, page }) => {
  const [, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={`${styles.progressBar} ${
        page == "users" ? styles.progressBarUsersPage : ""
      }`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      // style={{height: `${percentage}%`}}
    >
      <div
        className={`${styles.filler} ${
          page == "users" ? styles.fillerUsersPage : ""
        }`}
        style={{ height: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default VerticalProgressBar;
