import React, { useState, useEffect } from "react";
import * as styles from "./styles.module.scss";
import Table from "../../molecules/Table";
import { data } from "../../../constants/mostFrequentCompanies";

const MostFrequentCompaniesTable = () => {
  const [most_frequent_companies_data] = useState([...data]);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;
  const tableHeaders = [
    "Organisation",
    "Visits",
    "Affinity",
    "Info",
    "Number of Users",
  ];
  console.log("isMobile", isMobile);
  return (
    <div className={styles.container}>
      <Table
        tableHeaders={tableHeaders}
        data={most_frequent_companies_data}
        rowsPerPage={6}
        check
      />
    </div>
  );
};

export default MostFrequentCompaniesTable;
