import {
  GET_KENYA_ENGAGEMENTS,
  GET_USA_ENGAGEMENTS,
  GET_UGANDA_ENGAGEMENTS,
  GET_NORWAY_ENGAGEMENTS,
} from "../../constants/actionTypes";

const initialState = {
  kenyaEngagements: [],
  usaEngagements: [],
  ugandaEngagements: [],
  norwayEngagements: [],
  errors: {},
};

export const kenyaEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KENYA_ENGAGEMENTS:
      return {
        ...state,
        kenyaEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const usaEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USA_ENGAGEMENTS:
      return {
        ...state,
        usaEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const ugandaEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UGANDA_ENGAGEMENTS:
      return {
        ...state,
        ugandaEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const norwayEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NORWAY_ENGAGEMENTS:
      return {
        ...state,
        norwayEngagements: action.payload,
      };
    default:
      return state;
  }
};
