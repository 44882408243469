export const exportOptions = [
  {
    name: "PDF",
    id: 1,
  },
  {
    name: "CSV",
    id: 2,
  },
  {
    name: "PNG",
    id: 3,
  },
];
