import React, { Component } from "react";
import Datamap from "datamaps/dist/datamaps.world.min.js";
import * as styles from "./styles.module.scss";
import Spinner from "../../atoms/Spinner";

class ChoroplethMap extends Component {
  componentDidMount() {
    let dataset = {};
    const data = this.props.data;

    let map = new Datamap({
      scope: "world",
      element: document.getElementById("cloropleth_map"),
      responsive: true,
      projection: "mercator",
      fills: { defaultFill: "#4c4c4c", colorKey: "#0dc9a0" },
      data: dataset,
      geographyConfig: {
        borderColor: "#DEDEDE",
        borderWidth: 0.6,
        highlightBorderWidth: 2,
        highlightFillColor: function (geo) {
          return geo["fillColor"] || "#4c4c4c";
        },
        popupOnHover: false,
        highlightOnHover: false,
        highlightBorderColor: "#B7B7B7",
      },

      bubblesConfig: {
        background: "#0dc9a0",
        highlightOnHover: false,
        fillOpacity: 1,
        borderColor: "#444",
        borderWidth: 0.5,
      },
    });

    let arr = data.map((obj) => {
      return {
        radius: 8,
        fillKey: "colorKey",
        centered: obj["iso3"],
        country: obj["country"],
        count: obj["count"],
        percentage: obj["percentage"],
      };
    });

    map.bubbles(arr, {
      popupTemplate: function (geo, data) {
        if (!data) {
          return;
        }
        return [
          `<div class=${styles.customTooltip}>`,
          `<div class=${styles.inner}>`,
          `<p class=${styles.countryLabel}>`,
          `<span>`,
          geo.country,
          `</span>`,
          `</p>`,
          `<p class=${styles.percentageLabel}>`,
          `<span>`,
          data.percentage,
          `</span>`,
          `</p>`,
          `</div>`,
          `</div>`,
        ].join(``);
      },
    });

    window.addEventListener("resize", function () {
      map.resize();
    });

    return map;
  }

  render() {
    return this.props.data.length === 0 ? (
      <div id="cloropleth_map" className={styles.spinnerContainer}>
        <Spinner />
      </div>
    ) : (
      <div id="cloropleth_map" className={styles.container}></div>
    );
  }
}

export default ChoroplethMap;
