import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import CountryDistribution from "../components/molecules/CountryDistribution";
import AverageActiveDailyUsers from "../components/molecules/AverageActiveDailyUsers";
import UsersFromModules from "../components/molecules/UsersFromModules";
import PackagesBoughtByClients from "../components/molecules/PackagesBoughtByClients";
import MainPageOverview from "../components/molecules/MainPageOverview";
import Header from "../components/atoms/Header";

const Dashboard = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <ComponentsLayout>
          <Header page={"/dashboard"} />
          <MainPageOverview page={"/dashboard"} />
          <AverageActiveDailyUsers />
          <PackagesBoughtByClients />
          <UsersFromModules />
          <CountryDistribution />
        </ComponentsLayout>
      </main>
    </>
  );
};

export default Dashboard;
