export const usersPageOverviewData = [
  {
    title: "Average user time (min)",
    value: "7.7",
  },
  {
    title: "Active users",
    value: "85",
  },
  { title: "Inactive users", value: "23" },
];
