export const social_network_data = [
  {
    name: "Facebook",
    total: 165,
    percentage: 16,
  },
  {
    name: "Instagram",
    total: 170,
    percentage: 17,
  },
  {
    name: "Linkedin",
    total: 231,
    percentage: 23,
  },
  {
    name: "Youtube",
    total: 209,
    percentage: 20,
  },
  {
    name: "Others",
    total: 249,
    percentage: 24,
  },
];

export const tooltipLabels = [
  {
    key: "percentage",
    label: "Total",
  },
];
