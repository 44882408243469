import React, { useState, useEffect } from "react";
import OverviewOfPresentationsChart from "../../atoms/OverviewOfPresentationsChart";
import CalendarPicker from "../../atoms/CalendarPicker";
import * as styles from "./styles.module.scss";
import { tooltipLabels } from "../../../constants/overviewOfPresentationsData";
import Spinner from "../../atoms/Spinner";
import { presentations_data } from "../../../constants/overviewOfPresentationsData";

const OverviewOfPresentations = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Overview of presentations</h2>
        <div className={styles.legend}>
          <span>All Created Presentations</span>
          <span>New Presentations</span>
          <span>Shared presentations</span>
        </div>
        <div className={styles.dropDowns}>
          <CalendarPicker />
        </div>
      </div>
      {presentations_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {presentations_data.length > 0 &&
        presentations_data.map((i) => i !== undefined) && (
          <OverviewOfPresentationsChart
            data={presentations_data}
            tooltipLabels={tooltipLabels}
            isMobile={isMobile}
          />
        )}
    </div>
  );
};

export default OverviewOfPresentations;
