import React, { useState, useEffect } from "react";
import "regenerator-runtime/runtime";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import CalendarPicker from "../CalendarPicker";
import SocialPageStatsCard from "../SocialPageStatsCard";
import { social_page_stats_data } from "../../../constants/socialPageStatsData";
import * as styles from "./styles.module.scss";

const SocialPageStats = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 992;
  const isTablet = width > 992 && width <= 1024;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>
          Facebook<br></br> Stats
        </h2>
        <div className={styles.calendar}>
          <CalendarPicker component={"Social Page Stats"} />
        </div>
      </div>
      <div className={styles.content}>
        {!isMobile ? (
          <Swiper
            spaceBetween={isTablet ? 48 : 96}
            slidesPerView={isTablet ? 1 : 1}
            modules={[Navigation, Pagination]}
            navigation
            pagination
            className={styles.carousel}
          >
            {!social_page_stats_data
              ? ""
              : social_page_stats_data.map((item, index) => (
                  <SwiperSlide key={index} className={styles.slider}>
                    <SocialPageStatsCard info={item} />
                  </SwiperSlide>
                ))}
          </Swiper>
        ) : (
          <div className={styles.mobileContainer}>
            {!social_page_stats_data
              ? ""
              : social_page_stats_data.map((item, index) => (
                  <SocialPageStatsCard key={index} info={item} />
                ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default SocialPageStats;
