import React from "react";
import * as styles from "./styles.module.scss";

const PageNotice = () => {
  return (
    <div className={styles.container}>
      <div className={styles.animation}>
        <div className={styles.one} id={styles.spinOne}></div>
        <div className={styles.two} id={styles.spinTwo}></div>
        <div className={styles.three} id={styles.spinOne}></div>
      </div>
      <p>This page is still under development.</p>
    </div>
  );
};

export default PageNotice;
