import { combineReducers } from "redux";
import healthCheckReducer from "./healthcheck.reducer";
import {
  showroomEventsReducer,
  libraryEventsReducer,
  coverPageEventsReducer,
  presentationEventsReducer,
} from "../reducers/eventTypes.reducer";

import {
  kenyaEngagementReducer,
  usaEngagementReducer,
  ugandaEngagementReducer,
  norwayEngagementReducer,
} from "./countryEngagements.reducer";

import {
  chromeEngagementReducer,
  firefoxEngagementReducer,
  operaEngagementReducer,
  edgeEngagementReducer,
  safariEngagementReducer,
} from "./browserEngagements.reducer.js";

import {
  individualsReducer,
  organisationsReducer,
} from "./individualsVsOrganisations.reducer";

import { topSharedEventsReducer } from "./topSharedEvents.reducer";

import { topActiveCompaniesReducer } from "./topActiveCompanies.reducer";

import {
  usersReducer,
  sessionsReducer,
  directTrafficReducer,
} from "./generalOverview.reducer";
import { countryDistributionReducer } from "./countryDistribution.reducer";
import { mostFrequentUsersReducer } from "./mostFrequentUsers.reducer";
import { newAndReturningUsersReducer } from "./userEngagements.reducer";

import { usersTableReducer } from "./usersTable.reducer";

import { exportPageDataReducer } from "./exportPageData.reducer";
import {
  websitePageOverviewDataTodayReducer,
  websitePageOverviewData7daysReducer,
  websitePageOverviewData30daysReducer,
  websitePageOverviewData90daysReducer,
  websitePageOverviewData365daysReducer,
  websiteMostVisitedPagesData365daysReducer,
} from "./websitePage.reducer";

const rootReducer = combineReducers({
  healthCheck: healthCheckReducer,
  libraryEvents: libraryEventsReducer,
  showroomEvents: showroomEventsReducer,
  coverPageEvents: coverPageEventsReducer,
  presentationEvents: presentationEventsReducer,
  kenyaEngagements: kenyaEngagementReducer,
  usaEngagements: usaEngagementReducer,
  ugandaEngagements: ugandaEngagementReducer,
  norwayEngagements: norwayEngagementReducer,
  chromeEngagements: chromeEngagementReducer,
  firefoxEngagements: firefoxEngagementReducer,
  operaEngagements: operaEngagementReducer,
  edgeEngagements: edgeEngagementReducer,
  safariEngagements: safariEngagementReducer,
  individuals: individualsReducer,
  organisations: organisationsReducer,
  topSharedEvents: topSharedEventsReducer,
  topActiveCompanies: topActiveCompaniesReducer,
  users: usersReducer,
  sessions: sessionsReducer,
  directTraffic: directTrafficReducer,
  countryDistribution: countryDistributionReducer,
  mostFrequentUsers: mostFrequentUsersReducer,
  newAndReturningUsers: newAndReturningUsersReducer,
  usersTable: usersTableReducer,
  exportPageData: exportPageDataReducer,
  websitePageOverviewDataToday: websitePageOverviewDataTodayReducer,
  websitePageOverviewData7days: websitePageOverviewData7daysReducer,
  websitePageOverviewData30days: websitePageOverviewData30daysReducer,
  websitePageOverviewData90days: websitePageOverviewData90daysReducer,
  websitePageOverviewData365days: websitePageOverviewData365daysReducer,
  websiteMostVisitedPagesData365days: websiteMostVisitedPagesData365daysReducer,
});

export default rootReducer;
