import React from "react";
import * as styles from "./styles.module.scss";

const Spinner = () => {
  return (
    <div className={styles.spinnerAlign}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Spinner;
