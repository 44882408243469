import React from "react";
import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import * as styles from "./styles.module.scss";

const CustomBarChart = ({ data, tooltipLabels, isMobile, page }) => {
  const [color, setColor] = useState("");
  const [dataKey, setDataKey] = useState("");
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const tooltip = document.querySelector(".recharts-tooltip-wrapper-top");
    if (!tooltip) return;
    // Init tooltip values
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const spaceForLittleTriangle = 10;

    // Rewrite tooltip styles
    tooltip.style = `
        transform: translate(${position?.data.x}px, ${position?.data.y}px);
        pointer-events: none;  position: absolute;
        top: -${tooltipHeight + spaceForLittleTriangle}px;
        left: -${tooltipWidth / 2 - position?.data.width / 2}px;
        opacity: ${position?.show ? "1" : 0};
        transition: all 300ms ease 0s;
      `;
  }, [position]);

  const handleColor = (data, toolTipColor, dataKey) => {
    setColor(toolTipColor);
    setDataKey(dataKey);
    setPosition({ data: data, show: true });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={styles.customTooltip}>
          {payload.map((item) => {
            if (item.dataKey === dataKey) {
              return (
                <div
                  className={styles.inner}
                  style={{ backgroundColor: `${color}` }}
                  key={item["payload"][dataKey]}
                >
                  <p className={styles.toolTipHeader}>
                    <span>{item["payload"][dataKey]}%</span>
                  </p>
                  <p className={styles.pointer}></p>
                </div>
              );
            }
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={450}>
      <BarChart
        width={939}
        height={280}
        data={data}
        margin={{
          top: 90,
          bottom: 30,
          left: isMobile ? -18 : 10,
          right: isMobile ? 0 : 20,
        }}
      >
        <Bar
          dataKey={tooltipLabels[0].key}
          fill="#00a480"
          barSize={isMobile ? 30 : 40}
          radius={[8, 8, 0, 0]}
          onMouseMove={(data) =>
            handleColor(data, "#434343", tooltipLabels[0].key)
          }
          onMouseLeave={() => setPosition({ data: data, show: false })}
        />
        <Bar
          dataKey={tooltipLabels[1].key}
          fill="#0693da"
          barSize={isMobile ? 30 : 40}
          radius={[8, 8, 0, 0]}
          onMouseMove={(data) =>
            handleColor(data, "#434343", tooltipLabels[1].key)
          }
          onMouseLeave={() => setPosition({ data: data, show: false })}
        />
        <CartesianGrid stroke="#ced1d2" strokeWidth={0.15} vertical={false} />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 13, fill: "#FFFFFF" }}
          dy={page == "users" ? 0 : 13}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 13, fill: "#FFFFFF" }}
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          dx={isMobile ? 0 : -24}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={false}
          allowEscapeViewBox={{ x: true, y: true }}
          position={{
            x: position?.data.x ?? 0,
            y: position?.data.y ?? 0,
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
