import React, { useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { navItems } from "../../../constants/navItems";
import * as styles from "./styles.module.scss";
import NavItem from "../../atoms/NavItem";
import Toggle from "../../atoms/Toggle";

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      className={`${styles.navigation} ${expanded ? styles.expanded : ""}`}
    >
      <Container className={styles.container}>
        <Navbar.Collapse className={styles.collapse}>
          {navItems.map((item, index) => {
            return <NavItem key={index} item={item} expanded={expanded} />;
          })}
        </Navbar.Collapse>
        <Toggle
          onClick={() => setExpanded(expanded ? false : true)}
          expanded={expanded}
        />
      </Container>
    </Navbar>
  );
};

export default Navigation;
