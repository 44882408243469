import React, { useEffect } from "react";
import * as styles from "./styles.module.scss";

const TableFooter = ({ setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  return (
    <div className={styles.tableFooter}>
      {page == 1 ? (
        ""
      ) : (
        <button
          className={`${styles.button} ${styles.prevNextButton}`}
          onClick={() => setPage(page - 1)}
        >
          {"<"}
        </button>
      )}

      {/* {range.map((el, index) => (
        <button
          key={index}
          className={`${styles.button} ${
            page === el ? styles.activeButton : styles.inactiveButton
          }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))} */}

      <button
        className={`${styles.button} ${styles.prevNextButton}`}
        onClick={() => setPage(page + 1)}
      >
        {">"}
      </button>
    </div>
  );
};

export default TableFooter;
