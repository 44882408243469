import React from "react";
import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  Tooltip,
} from "recharts";
import SocialIcon from "../SocialIcon";
import * as styles from "./styles.module.scss";

const SocialNetworkChart = ({ data, tooltipLabels, isMobile }) => {
  const [color, setColor] = useState("");
  const [dataKey, setDataKey] = useState("");
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const tooltip = document.querySelector(".recharts-tooltip-wrapper-top");
    if (!tooltip) return;
    // Init tooltip values
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const spaceForLittleTriangle = 10;

    // Rewrite tooltip styles
    tooltip.style = `
      transform: translate(${position?.data.x}px, ${position?.data.y}px);
      pointer-events: none;  position: absolute;
      top: -${tooltipHeight + spaceForLittleTriangle}px;
      left: -${tooltipWidth / 2 - position?.data.width / 2}px;
      opacity: ${position?.show ? "1" : 0};
      transition: all 300ms ease 0s;
    `;
  }, [position]);

  const handleColor = (data, toolTipColor, dataKey) => {
    setColor(toolTipColor);
    setDataKey(dataKey);
    setPosition({ data: data, show: true });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={styles.customTooltip}>
          {payload.map((item) => {
            if (item.dataKey === dataKey) {
              return (
                <div
                  className={styles.inner}
                  style={{ backgroundColor: `${color}` }}
                  key={item["payload"][dataKey]}
                >
                  <p className={styles.toolTipHeader}>
                    <span> {item["payload"]["name"]}</span>
                  </p>
                  <p className={styles.toolTipHeader}>
                    <span> {item["payload"]["percentage"]}%</span>
                  </p>
                  <p className={styles.toolTipText}>
                    <span>{item["payload"]["total"]}</span>
                  </p>
                  <p className={styles.pointer}></p>
                </div>
              );
            }
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={440}>
      <BarChart
        width={939}
        height={280}
        data={data}
        margin={{
          top: 90,
          bottom: 60,
          left: isMobile ? -18 : 10,
          right: isMobile ? 0 : 20,
        }}
      >
        <Bar
          dataKey={tooltipLabels[0].key}
          fill="#00a480"
          radius={[12, 12, 0, 0]}
          barSize={80}
          onMouseMove={(data) =>
            handleColor(data, "#434343", tooltipLabels[0].key)
          }
          onMouseLeave={() => setPosition({ data: data, show: false })}
        />
        <CartesianGrid stroke="none" vertical={false} />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={<SocialIcon />}
          dy={0}
        />
        <Tooltip
          active={true}
          content={<CustomTooltip />}
          cursor={false}
          allowEscapeViewBox={{ x: false, y: false }}
          position={{
            x: position?.data.x ?? 0,
            y: position?.data.y ?? 0,
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SocialNetworkChart;
