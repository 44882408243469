export const data = [
  {
    id: 1,
    organisation: "Microsoft",
    visits: 250,
    percentage: "12%",
    info: "Technology",
    users: 76,
  },
  {
    id: 2,
    organisation: "Apple",
    visits: 850,
    percentage: "53%",
    info: "Technology",
    users: 175,
  },
  {
    id: 3,
    organisation: "Coca cola",
    visits: 153,
    percentage: "62%",
    info: "Beverages",
    users: 371,
  },
  {
    id: 4,
    organisation: "GoDaddy",
    visits: 550,
    percentage: "42%",
    info: "Internet",
    users: 71,
  },
  {
    id: 5,
    organisation: "Pepsi",
    visits: 650,
    percentage: "22%",
    info: "Beverages",
    users: 95,
  },
  {
    id: 5,
    organisation: "Samsung",
    visits: 332,
    percentage: "88%",
    info: "Electronics",
    users: 754,
  },
  {
    id: 6,
    organisation: "Springboard",
    visits: 553,
    percentage: "52%",
    info: "Education",
    users: 275,
  },
  {
    id: 7,
    organisation: "Colombia Records",
    visits: 258,
    percentage: "92%",
    info: "Music",
    users: 331,
  },
  {
    id: 8,
    organisation: "Dell",
    visits: 250,
    percentage: "68%",
    info: "Technology",
    users: 2005,
  },
  {
    id: 9,
    organisation: "HP",
    visits: 250,
    percentage: "97%",
    info: "Technology",
    users: 7665,
  },
  {
    id: 10,
    organisation: "WFP",
    visits: 250,
    percentage: "62%",
    info: "Food",
    users: 4457,
  },
  {
    id: 11,
    organisation: "Space X",
    visits: 250,
    percentage: "44%",
    info: "Aviation",
    users: 705,
  },
  {
    id: 12,
    organisation: "Google",
    visits: 250,
    percentage: "57%",
    info: "Technology",
    users: 93,
  },
  {
    id: 13,
    organisation: "Linkedin",
    visits: 250,
    percentage: "12%",
    info: "Jobs",
    users: 613,
  },
  {
    id: 14,
    organisation: "Amazon",
    visits: 250,
    percentage: "64%",
    info: "Technology",
    users: 332,
  },
];
