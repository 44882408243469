import React from "react";
import * as styles from "./styles.module.scss";

const NumericDataCard = ({
  descriptionOne,
  valueOne,
  descriptionTwo,
  valueTwo,
  descriptionThree,
  valueThree,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.value}>{valueOne}</h2>
        <p className={styles.description}>{descriptionOne}</p>
        <h2 className={styles.value}>{valueTwo}</h2>
        <p className={styles.description}>{descriptionTwo}</p>
        <h2 className={styles.value}>{valueThree}</h2>
        <p className={styles.description}>{descriptionThree}</p>
      </div>
    </div>
  );
};

export default NumericDataCard;
