export const socials = [
  {
    name: "Facebook",
    id: 1,
  },
  {
    name: "Instagram",
    id: 2,
  },
  {
    name: "Linkedin",
    id: 3,
  },
];
