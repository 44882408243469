import React, { useState, useEffect } from "react";
import ActiveCompany from "../../molecules/ActiveCompany";
import { data } from "../../../constants/top10Companies";
import * as styles from "./styles.module.scss";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";

const Top10ActiveCompanies = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 992;
  const isTablet = width > 992 && width <= 1024;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Top 10 Active Companies</h2>
        <h3>
          Summary of top most shared presentations by different Organisations
        </h3>
      </div>
      {!isMobile ? (
        <Swiper
          spaceBetween={isTablet ? 48 : 96}
          slidesPerView={isTablet ? 3 : 5}
          modules={[Navigation]}
          navigation
          className={styles.carousel}
        >
          {!data
            ? ""
            : data.map((item, index) => (
                <SwiperSlide key={index}>
                  <ActiveCompany info={item} />
                </SwiperSlide>
              ))}
        </Swiper>
      ) : (
        <div className={styles.mobileContainer}>
          {!data
            ? ""
            : data.map((item, index) => (
                <ActiveCompany key={index} info={item} />
              ))}
        </div>
      )}
    </div>
  );
};

export default Top10ActiveCompanies;
