import { GET_COUNTRY_DISTRIBUTION } from "../../constants/actionTypes";

const initialState = {
  countryDistribution: [],
  errors: {},
};

export const countryDistributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_DISTRIBUTION:
      return {
        ...state,
        countryDistribution: action.payload,
      };
    default:
      return state;
  }
};
