export const browsers = [
  {
    browserName: "Chrome",
  },
  {
    browserName: "Firefox",
  },
  {
    browserName: "Opera",
  },
  {
    browserName: "Edge",
  },
  {
    browserName: "Safari",
  },
];
