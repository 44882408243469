import React, { useState, useEffect } from "react";
import UsersFromModulesChart from "../../atoms/UsersFromModulesChart";
import * as styles from "./styles.module.scss";
import { tooltipLabels } from "../../../constants/userData";
import Spinner from "../../atoms/Spinner";
import CalendarPicker from "../../atoms/CalendarPicker";
import { user_data } from "../../../constants/userData";
import libraryLogo from "../../../assets/images/icons/library-logo.svg";
import studioLogo from "../../../assets/images/icons/studio-logo.svg";
import connectLogo from "../../../assets/images/icons/connect-logo.svg";

const UsersFromModules = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Users from modules</h2>
        <div className={styles.legend}>
          <div className={styles.content}>
            <span>
              <p>
                <img src={libraryLogo} alt="library logo" />
              </p>
              <p id={styles.text}>Library</p>
            </span>
          </div>

          <div>
            <span>
              <p>
                <img src={studioLogo} alt="studio logo" />
              </p>
              <p id={styles.text}>Studio</p>
            </span>
          </div>

          <div>
            <span>
              <p>
                <img src={connectLogo} alt="connect logo" />
              </p>
              <p id={styles.text}>Connect</p>
            </span>
          </div>
        </div>
        <div className={styles.dropDowns}>
          <CalendarPicker />
        </div>
      </div>
      {user_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      {user_data.length > 0 && user_data.map((i) => i !== undefined) && (
        <UsersFromModulesChart
          data={user_data}
          tooltipLabels={tooltipLabels}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default UsersFromModules;
