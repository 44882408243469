import React from "react";
import { useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as styles from "./styles.module.scss";

const NavItem = ({ item, expanded }) => {
  const location = useLocation();

  return (
    <Nav className={`${styles.item} ${expanded ? styles.expanded : ""}`}>
      <NavLink
        className={styles.link}
        to={item.path}
        activeClassName={styles.active}
        exact
      >
        <img
          className={styles.icon}
          src={location.pathname == item.path ? item.iconActive : item.icon}
          alt={item.alt}
        />
        <span className={styles.label}>{item.label}</span>
      </NavLink>
    </Nav>
  );
};

export default NavItem;
