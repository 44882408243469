export const months = [
  "Jan ",
  "Feb ",
  "Mar ",
  "Apr ",
  "May ",
  "Jun ",
  "Jul ",
  "Aug ",
  "Sep ",
  "Oct ",
  "Nov ",
  "Dec ",
];

export const days = ["Su", "Mo", "Tu", "We", "Thu", "Fri", "Sa"];
