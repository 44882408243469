import React from "react";
import * as styles from "./styles.module.scss";

import { headerItems } from "../../../constants/headerItems";

const Header = ({ page }) => {
  return (
    <div>
      {(page === "/dashboard" ||
        page === "/socials" ||
        page === "/link-sharing" ||
        page === "/users" ||
        page === "/errors-warnings" ||
        page === "/website") && (
        <header className={styles.socialsHeader}>
          {headerItems[page] ? (
            <>
              <h1>{headerItems[page].title}</h1>
              <h2>{headerItems[page].subtitle}</h2>
              <img
                src={headerItems[page].image}
                alt={`${headerItems[page].title} Background`}
              />
            </>
          ) : (
            ""
          )}
        </header>
      )}

      {page === "/other" && (
        <header className={styles.header}>
          {headerItems[page] ? (
            <>
              <h1>{headerItems[page].title}</h1>
              <h2>{headerItems[page].subtitle}</h2>
              <img
                src={headerItems[page].image}
                alt={`${headerItems[page].title} Background`}
              />
            </>
          ) : (
            ""
          )}
        </header>
      )}

      {(page === "/library" || page === "/connect" || page === "/studio") && ""}
    </div>
  );
};

export default Header;
