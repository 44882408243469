import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "regenerator-runtime/runtime";
import * as styles from "./styles.module.scss";
import VerticalProgressBar from "../../atoms/VerticalProgressBar";
// import { percentageCalculator } from "../../../helpers/percentageCalculator";
import { browserEngagements } from "../../../constants/browserEngagements";
import { browsers } from "../../../constants/browsers";
import {
  getChromeEngagements,
  getFirefoxEngagements,
  getOperaEngagements,
  getEdgeEngagements,
  getSafariEngagements,
} from "../../../state/actions/browserEngagements.actions";
// import CalendarBar from "../../atoms/CalendarBar";

const BrowserEngagements = ({ page }) => {
  const dispatch = useDispatch();

  const [, setAllChromeEngagements] = useState(null);
  const [, setAllFirefoxEngagements] = useState(null);
  const [, setAllOperaEngagements] = useState(null);
  const [, setAllEdgeEngagements] = useState(null);
  const [, setAllSafariEngagements] = useState(null);

  const chromeEngagementCount = useSelector(
    (state) => state.chromeEngagements.chromeEngagements.count
  );
  const firefoxEngagementCount = useSelector(
    (state) => state.firefoxEngagements.firefoxEngagements.count
  );
  const operaEngagementCount = useSelector(
    (state) => state.operaEngagements.operaEngagements.count
  );
  const edgeEngagementCount = useSelector(
    (state) => state.edgeEngagements.edgeEngagements.count
  );
  const safariEngagementCount = useSelector(
    (state) => state.safariEngagements.safariEngagements.count
  );

  const allState = useSelector((state) => state);

  const totalEngagements =
    chromeEngagementCount +
    firefoxEngagementCount +
    operaEngagementCount +
    edgeEngagementCount +
    safariEngagementCount;
  console.log("totalEngagements", totalEngagements);

  useEffect(() => {
    const allChromeEngagements = () => {
      let chromeEngagements = async () =>
        await dispatch(getChromeEngagements());
      setAllChromeEngagements(chromeEngagements);
    };
    allChromeEngagements();
  }, []);

  useEffect(() => {
    const allFirefoxEngagements = () => {
      let firefoxEngagements = async () =>
        await dispatch(getFirefoxEngagements());
      setAllFirefoxEngagements(firefoxEngagements);
    };
    allFirefoxEngagements();
  }, []);

  useEffect(() => {
    const allOperaEngagements = () => {
      let operaEngagements = async () => await dispatch(getOperaEngagements());
      setAllOperaEngagements(operaEngagements);
    };
    allOperaEngagements();
  }, []);

  useEffect(() => {
    const allEdgeEngagements = () => {
      let edgeEngagements = async () => await dispatch(getEdgeEngagements());
      setAllEdgeEngagements(edgeEngagements);
    };
    allEdgeEngagements();
  }, []);

  useEffect(() => {
    const allSafariEngagements = () => {
      let safariEngagements = async () =>
        await dispatch(getSafariEngagements());
      setAllSafariEngagements(safariEngagements);
    };
    allSafariEngagements();
  }, []);

  const test = browserEngagements.map((item, index) => {
    if (index === 0) {
      return { ...item, percentage: 60, value: 60 };
    } else if (index === 1) {
      return { ...item, percentage: 15, value: 15 };
    } else if (index === 2) {
      return { ...item, percentage: 10, value: 10 };
    } else if (index === 3) {
      return { ...item, percentage: 5, value: 5 };
    } else {
      return { ...item, percentage: 10, value: 10 };
    }
  });
  console.log("test", test);

  return (
    <div
      className={`${styles.container} ${
        page == "users" ? styles.containerUsersPage : ""
      }`}
    >
      <div className={styles.header}>
        <div className={`${styles.titleCalendar}`}>
          <h2>Browser Engagements</h2>
        </div>
        <p>Summary of link sharing per browser.</p>
      </div>
      <div className={styles.verticalContainer}>
        {test.map((browserEngagement, index) => {
          let engagementCount =
            allState[browserEngagement.engagement][browserEngagement.engagement]
              .count;
          if (engagementCount !== null) {
            return (
              <div key={browserEngagement.browser}>
                <div className={styles.item}>
                  <span className={styles.percentage}>
                    {browserEngagement.percentage}%
                  </span>
                  <span className={styles.value}>
                    {browserEngagement.value}
                  </span>
                  <VerticalProgressBar
                    browser={browserEngagement.browser}
                    users={engagementCount}
                    percentage={browserEngagement.percentage}
                    //   percentageCalculator(
                    //   engagementCount,
                    //   totalEngagements
                    // )}
                    page={page}
                  />
                  <span>{browsers[index].browserName}</span>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default BrowserEngagements;
