import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import Table from "../../molecules/Table";
import { keywords } from "../../../constants/websiteData";

const SocialFlexTable = () => {
  const [keywordsData] = useState([...keywords]);

  const tableHeaders = ["Keyword", "Volume"];

  return (
    <div className={styles.flexContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Paid keywords</h2>
        </div>

        <div className={styles.tableContainer} id={styles.scroll}>
          <Table
            tableHeaders={tableHeaders}
            data={keywordsData}
            check
            rowsPerPage={keywordsData?.length}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Organic keywords</h2>
        </div>
        <div className={styles.tableContainer}>
          <Table
            tableHeaders={tableHeaders}
            data={keywordsData}
            check
            rowsPerPage={keywordsData?.length}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialFlexTable;
