import React, { useState, useEffect } from "react";
import LinearChart from "../../atoms/LinearChart";
import * as styles from "./styles.module.scss";
import { tooltipLabels } from "../../../constants/userData";
import Spinner from "../../atoms/Spinner";
import CalendarPicker from "../../atoms/CalendarPicker";
import Dropdown from "../../atoms/DropDown";
import { user_data } from "../../../constants/userData";
import { modules } from "../../../constants/modules";

const AverageActiveDailyUsers = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Average Active daily users</h2>
        <div className={styles.legend}>
          <span>All Users</span>
          <span>New Users</span>
          <span>Returning Users</span>
        </div>
        <div className={styles.dropDowns}>
          <Dropdown title={modules[0]["name"]} data={modules} />
          <CalendarPicker />
        </div>
      </div>
      {user_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      {user_data.length > 0 && user_data.map((i) => i !== undefined) && (
        <LinearChart
          data={user_data}
          tooltipLabels={tooltipLabels}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default AverageActiveDailyUsers;
