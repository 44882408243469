import React, { useState, useEffect } from "react";
import * as styles from "./styles.module.scss";
import Table from "../../molecules/Table";
import { websiteTrafficData } from "../../../constants/websiteData";

const KeywordSearchTraffic = () => {
  const [trafficData] = useState([...websiteTrafficData]);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;
  const tableHeaders = [
    "Keywords",
    "Avg weekly searches",
    "Competition",
    "Ranking",
  ];
  console.log("isMobile", isMobile);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Keyword search traffic</h2>
      </div>
      <Table
        tableHeaders={tableHeaders}
        data={trafficData}
        rowsPerPage={7}
        check
      />
    </div>
  );
};

export default KeywordSearchTraffic;
