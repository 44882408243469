import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import useTable from "../../../helpers/useTable";
import TableFooter from "../../atoms/TableFooter";
import ErrorModal from "../ErrorModal";

const Table = ({ tableHeaders, type, data, rowsPerPage, check }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  const [show, setShow] = useState(false);
  const [errorWarningId, setErrorWarningId] = useState(null);

  const changeShowValue = (newState) => {
    setShow(newState);
  };

  return (
    <>
      {type === "errors-warnings" && (
        <ErrorModal
          data={data}
          show={show}
          handleClose={changeShowValue}
          errorWarningId={errorWarningId}
        />
      )}
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>
              {!check && (
                <label className={styles.checkboxContainer}>
                  <input className={styles.checkbox} type="checkbox" />
                  <span className={styles.checkmark}></span>
                </label>
              )}
            </th>
            {tableHeaders &&
              tableHeaders.map((header, index) => (
                <th key={index} className={styles.tableHeader}>
                  {header}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {slice.slice(1).map((el) => (
            <tr
              className={styles.tableRowItems}
              key={el.id + Math.floor(Math.random() * 1000)}
            >
              <td className={styles.tableCell}>
                {!check && (
                  <label className={styles.checkboxContainer}>
                    <input className={styles.checkbox} type="checkbox" />
                    <span className={styles.checkmark}></span>
                  </label>
                )}
              </td>
              {Object.values(el)
                .slice(0)
                .map((item, index) => (
                  <td
                    key={index}
                    style={{
                      color:
                        item === "Warning"
                          ? "#0693da"
                          : item === "Error"
                          ? "#d12a0c"
                          : "",
                    }}
                    className={styles.tableCell}
                  >
                    {item}
                  </td>
                ))}
              {type === "errors-warnings" && (
                <td
                  className={`${styles.tableCell} `}
                  onClick={() => {
                    setShow(true);
                    setErrorWarningId(el.id);
                  }}
                >
                  <div className={styles.sectionButtons}>
                    <div>View</div>
                    <div>Clear</div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;
