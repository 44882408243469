import { GET_EXPORT_DATA } from "../../constants/actionTypes";

const initialState = {
  exportPageData: [],
  errors: {},
};

export const exportPageDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPORT_DATA:
      return {
        ...state,
        exportPageData: action.payload,
      };
    default:
      return state;
  }
};
