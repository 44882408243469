import usersIcon from "../assets/images/icons/icon-users.svg";
import presentationsIcon from "../assets/images/icons/icon-presentations.svg";
import timerIcon from "../assets/images/icons/timer-icon.svg";
import screenIcon from "../assets/images/icons/screen-icon.svg";

export const DashboardPageOverviewData = [
  { title: "Total Users", value: 400, percentage: "30%", icon: usersIcon },
  {
    title: "Shared Presentations",
    value: 32,
    percentage: "45%",
    icon: presentationsIcon,
  },
  {
    title: "Average time (min)",
    value: "30",
    percentage: "81%",
    icon: timerIcon,
  },
  { title: "Screen Time", value: 50, percentage: "55%", icon: screenIcon },
];

export const mainPageOverviewData = [
  {
    title: "Total Users",
    value: 500,
    increase: "3%",
    decrease: null,
    percentage: "30%",
    icon: usersIcon,
  },
  {
    title: "Shared Presentations",
    value: 430,
    increase: "1.8%",
    decrease: null,
    percentage: "45%",
    icon: presentationsIcon,
  },
  {
    title: "Traffic",
    value: "40%",
    increase: "1.2%",
    decrease: null,
    percentage: "45%",
    icon: timerIcon,
  },
  {
    title: "Sessions",
    value: 534,
    increase: "0.8%",
    decrease: null,
    percentage: "81%",
    icon: screenIcon,
  },
];

export const socialWebsiteData = [
  {
    title: "Visits",
    value: "1.2M",
    increase: "0.8%",
    decrease: null,
  },
  {
    title: "Pages/Visit",
    value: "6.60",
    increase: "4.5%",
    decrease: null,
  },
  {
    title: "Avg.Visit Duration",
    value: "12:12",
    increase: "9.2%",
    decrease: null,
  },
  {
    title: "Bounce Rate",
    value: "35%",
    increase: "15%",
    decrease: null,
  },
];
