import React from "react";
import placeholder_img from "../../../assets/images/presentations/mw-pp-icon.png";
import * as styles from "./styles.module.scss";

const OrganisationPresentation = ({ data }) => {
  return (
    <div className={styles.container}>
      <img src={data.image ? data.image : placeholder_img}></img>
      <div className={styles.content}>
        <div className={styles.title}>
          <a href={data.event_properties_url} target="_blank" rel="noreferrer">
            {data.name ? data.name : "Presentation Link"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrganisationPresentation;
