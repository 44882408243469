import React, { useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { navItems } from "../../../constants/navItems";
import * as styles from "./styles.module.scss";
import NavItem from "../../atoms/NavItem";
import Logo from "../../atoms/Logo";
import Toggle from "../../atoms/Toggle";
import search from "../../../assets/images/icons/search.png";
import download from "../../../assets/images/icons/download.svg";

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      className={`${styles.navigation} ${expanded ? styles.expanded : ""}`}
    >
      <Container className={styles.container}>
        <Logo expanded={expanded} />
        <Navbar.Collapse className={styles.collapse}>
          {navItems.map((item, index) => {
            return <NavItem key={index} item={item} expanded={expanded} />;
          })}
        </Navbar.Collapse>
        <div className={styles.icons}>
          <img src={search} alt="Search Icon" />
          <img
            src={download}
            alt="Export Icon"
            title={"Loading..."}
            className={styles.disabled}
          />
          <Toggle
            onClick={() => setExpanded(expanded ? false : true)}
            expanded={expanded}
            mobile={true}
          />
        </div>
      </Container>
      {!expanded ? (
        ""
      ) : (
        <div
          className={styles.overlay}
          onClick={() => setExpanded(expanded ? false : true)}
        ></div>
      )}
    </Navbar>
  );
};

export default Navigation;
