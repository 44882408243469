import React from "react";
import { Container } from "react-bootstrap";

const LandingLayout = ({ children }) => (
  <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
    {children}
  </Container>
);

export default LandingLayout;
