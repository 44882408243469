import React from "react";
import * as styles from "./styles.module.scss";

const UsersPageOverviewCard = ({ title, value }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{value}</div>
      </div>
    </div>
  );
};

export default UsersPageOverviewCard;
