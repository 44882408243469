import React from "react";
import searchIcon from "../../../assets/images/icons/search.png";

import * as styles from "./styles.module.scss";

const SearchBar = () => {
  return (
    <div className={styles.container}>
      <input type="text" name="s" id="s" placeholder="Search..." />
      <img src={searchIcon} alt="Search Icon" />
    </div>
  );
};

export default SearchBar;
