import {
  GET_WEBSITE_PAGE_OVERVIEW_DATA_TODAY,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_7DAYS,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_30DAYS,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_90DAYS,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_365DAYS,
  GET_WEBSITE_MOST_VISITED_PAGES_DATA_365DAYS,
} from "../../constants/actionTypes";

const initialState = {
  websitePageOverviewDataToday: [],
  websitePageOverviewData7days: [],
  websitePageOverviewData30days: [],
  websitePageOverviewData90days: [],
  websitePageOverviewData365days: [],
  websiteMostVisitedPagesData365days: [],
  errors: {},
};

export const websitePageOverviewDataTodayReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_WEBSITE_PAGE_OVERVIEW_DATA_TODAY:
      return {
        ...state,
        websitePageOverviewDataToday: action.payload,
      };
    default:
      return state;
  }
};

export const websitePageOverviewData7daysReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_WEBSITE_PAGE_OVERVIEW_DATA_7DAYS:
      return {
        ...state,
        websitePageOverviewData7days: action.payload,
      };
    default:
      return state;
  }
};

export const websitePageOverviewData30daysReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_WEBSITE_PAGE_OVERVIEW_DATA_30DAYS:
      return {
        ...state,
        websitePageOverviewData30days: action.payload,
      };
    default:
      return state;
  }
};

export const websitePageOverviewData90daysReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_WEBSITE_PAGE_OVERVIEW_DATA_90DAYS:
      return {
        ...state,
        websitePageOverviewData90days: action.payload,
      };
    default:
      return state;
  }
};

export const websitePageOverviewData365daysReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_WEBSITE_PAGE_OVERVIEW_DATA_365DAYS:
      return {
        ...state,
        websitePageOverviewData365days: action.payload,
      };
    default:
      return state;
  }
};

export const websiteMostVisitedPagesData365daysReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_WEBSITE_MOST_VISITED_PAGES_DATA_365DAYS:
      return {
        ...state,
        websiteMostVisitedPagesData365days: action.payload,
      };
    default:
      return state;
  }
};
