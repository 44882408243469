import dashboard from "../assets/images/icons/dashboard.svg";
import users from "../assets/images/icons/users.svg";
import linkSharing from "../assets/images/icons/link-sharing.svg";
import library from "../assets/images/icons/library.svg";
import studio from "../assets/images/icons/studio.svg";
import connect from "../assets/images/icons/connect.svg";
import website from "../assets/images/icons/website.svg";
import social from "../assets/images/icons/social.svg";
import errors from "../assets/images/icons/errors.svg";

import dashboardActive from "../assets/images/icons/dashboard-active.svg";
import usersActive from "../assets/images/icons/users-active.svg";
import linkSharingActive from "../assets/images/icons/link-sharing-active.svg";
import studioActive from "../assets/images/icons/studio-active.svg";
import connectActive from "../assets/images/icons/connect-active.svg";
import websiteActive from "../assets/images/icons/website-active.svg";
import socialActive from "../assets/images/icons/social-active.svg";
import errorsActive from "../assets/images/icons/errors-active.svg";
//at the moment there is no library  active svg file on the design
import libraryActive from "../assets/images/icons/library.svg";

export const navItems = [
  {
    path: "/dashboard",
    label: "Main",
    icon: dashboard,
    iconActive: dashboardActive,
    alt: "Dashboard Icon",
  },
  {
    path: "/users",
    label: "Users",
    icon: users,
    iconActive: usersActive,
    alt: "Users Icon",
  },
  {
    path: "/link-sharing",
    label: "Link Sharing",
    icon: linkSharing,
    iconActive: linkSharingActive,
    alt: "Link Sharing Icon",
  },
  {
    path: "/library",
    label: "Library",
    icon: library,
    iconActive: libraryActive,
    alt: "Library Icon",
  },
  {
    path: "/studio",
    label: "Studio",
    icon: studio,
    iconActive: studioActive,
    alt: "Studio Icon",
  },
  {
    path: "/connect",
    label: "Connect",
    icon: connect,
    iconActive: connectActive,
    alt: "Connect Icon",
  },
  {
    path: "/website",
    label: "Website",
    icon: website,
    iconActive: websiteActive,
    alt: "Website Icon",
  },
  {
    path: "/socials",
    label: "Social & BI Report",
    icon: social,
    iconActive: socialActive,
    alt: "Social Icon",
  },
  {
    path: "/errors-warnings",
    label: "Errors & Warnings",
    icon: errors,
    iconActive: errorsActive,
    alt: "Errors Icon",
  },
];
