import React from "react";
import Landing from "../components/molecules/Landing";
import LandingHeaderLayout from "../components/molecules/LandingHeaderLayout";
const LandingPage = () => {
  return (
    <>
      <LandingHeaderLayout />
      <main>
        <Landing />
      </main>
    </>
  );
};

export default LandingPage;
