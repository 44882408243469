import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as styles from "./styles.module.scss";
import Table from "../../molecules/Table";
import { getWebsiteMostVisitedPages365days } from "../../../state/actions/websitePage.actions";

const WebsiteTable = () => {
  const [, setWebsiteMostVisitedPages365days] = useState(null);

  const dispatch = useDispatch();

  const websiteMostVisitedPages365days = useSelector(
    (state) =>
      state.websiteMostVisitedPagesData365days
        .websiteMostVisitedPagesData365days
  );

  useEffect(() => {
    const fetchWebsiteMostVisitedPages365days = () => {
      let fetchedWebsiteMostVisitedPages365days = async () =>
        await dispatch(getWebsiteMostVisitedPages365days());
      setWebsiteMostVisitedPages365days(fetchedWebsiteMostVisitedPages365days);
    };
    fetchWebsiteMostVisitedPages365days();
  }, []);

  const tableHeaders = [
    "Pages",
    "Date",
    "Views",
    "Users",
    "Views per user",
    "Average engagement time",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Most visited pages</h2>
      </div>
      <Table
        tableHeaders={tableHeaders}
        data={websiteMostVisitedPages365days}
        rowsPerPage={7}
        check
      />
    </div>
  );
};

export default WebsiteTable;
