import React from "react";
import * as styles from "./styles.module.scss";
import CalendarPicker from "../../atoms/CalendarPicker";
import { numeric_data } from "../../../constants/numericData";
import { socials } from "../../../constants/socials";
import DropDown from "../../atoms/DropDown";
import NumericDataCard from "../../atoms/NumericDataCard";
import Spinner from "../../atoms/Spinner";

const DifferentNumericData = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Different Numeric Data</h2>
        <div className={styles.calendar}>
          <CalendarPicker />
        </div>
        <div className={styles.dropDown}>
          <DropDown title={socials[0]["name"]} data={socials} />
        </div>
      </div>
      {numeric_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      <div className={styles.cards}>
        {numeric_data.map((item, index) => (
          <div key={index} className={styles.card}>
            <NumericDataCard
              descriptionOne={item["descriptionOne"]}
              valueOne={item["valueOne"]}
              descriptionTwo={item["descriptionTwo"]}
              valueTwo={item["valueTwo"]}
              descriptionThree={item["descriptionThree"]}
              valueThree={item["valueThree"]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DifferentNumericData;
