import React, { useState } from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import * as styles from "./styles.module.scss";

const UsersFromModulesChart = ({ data, tooltipLabels, isMobile, page }) => {
  const [color, setColor] = useState("");
  const [dataKey, setDataKey] = useState("");

  const handleColor = (toolTipColor, dataKey) => {
    setColor(toolTipColor);
    setDataKey(dataKey);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={styles.customTooltip}>
          {payload.map((item) => {
            if (item.dataKey === dataKey) {
              return (
                <div
                  className={styles.inner}
                  style={{ backgroundColor: `${color}` }}
                  key={item["payload"][dataKey]}
                >
                  <p className={styles.toolTipHeader}>
                    <span>{item["payload"][dataKey]} %</span>
                  </p>
                  <p className={styles.toolTipText}>
                    <span>
                      {item["payload"]["new_users"] +
                        item["payload"]["returning_users"]}
                    </span>
                  </p>
                  <p className={styles[`${dataKey}`]}></p>
                </div>
              );
            }
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={375}>
      <AreaChart
        width={939}
        height={280}
        data={data}
        margin={{
          top: 90,
          bottom: 30,
          left: isMobile ? -20 : 10,
          right: isMobile ? 0 : 20,
        }}
      >
        <defs>
          <linearGradient id="colorLabel2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#0693da" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#0693da" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 13, fill: "#FFFFFF" }}
          dy={page == "users" ? 0 : 16}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 13, fill: "#FFFFFF" }}
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          dx={isMobile ? 0 : -24}
        />
        <CartesianGrid
          stroke="#ced1d2"
          strokeWidth={0.15}
          horizontal={true}
          vertical={false}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={false}
          allowEscapeViewBox={{ x: true, y: true }}
          offset={11}
        />

        <Area
          type="monotone"
          dataKey={tooltipLabels[2].key}
          stroke="#0693da"
          fillOpacity={1}
          fill="url(#colorLabel2)"
          activeDot={{
            onMouseOver: () => handleColor("#0693da", tooltipLabels[2].key),
            onMouseLeave: () => handleColor("", ""),
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default UsersFromModulesChart;
