// import dashboardBg from "../assets/images/background/main-background.png";
import mwconnectBg from "../assets/images/background/wizr-connect-bg.png";
import usersBg from "../assets/images/background/user-background.png";
import socialsBg from "../assets/images/background/socials-background.png";
import websiteBg from "../assets/images/background/webBanner.png";
import linkSharingBg from "../assets/images/background/link-sharing-bg.png";
import errorsBg from "../assets/images/background/errors-background-bg.png";

export const headerItems = {
  "/dashboard": {
    title: "Dashboard",
    titlePart: "Data Board",
    subtitle: "Tracking key Presentations, Companies, Trends and Usage",
    image: usersBg,
  },
  "/link-sharing": {
    title: "Link sharing",
    titlePart: "Link sharing",
    subtitle: "Tracking key link sharing insights, Trends and Usage.",
    image: linkSharingBg,
  },
  "/users": {
    title: "Users",
    titlePart: "Users",
    subtitle: "Tracking key user specific data across all platforms.",
    image: socialsBg,
  },
  "/library": {
    title: "",
    titlePart: "",
    subtitle: "",
    image: "",
  },
  "/studio": {
    title: "Studio",
    titlePart: "Studio",
    subtitle: "",
    image: usersBg,
  },
  "/connect": {
    title: "",
    titlePart: "",
    subtitle: "",
    image: "",
  },
  "/wizr-connect": {
    title: "The WiZR CONNECT",
    titlePart: "Data Board",
    subtitle: "Tracking key Presentations, Companies, Trends and Usage.",
    image: mwconnectBg,
  },
  "/socials": {
    title: "Socials - BI reports",
    titlePart: "Socials - BI reports",
    subtitle: "Tracking key social insights, Trends and Usage.",
    image: socialsBg,
  },
  "/website": {
    title: "Socials - Website",
    titlePart: "Socials - Website",
    subtitle: "Tracking key website user engagement and data insights.",
    image: websiteBg,
  },
  "/errors-warnings": {
    title: "Errors & Warnings",
    titlePart: "Errors & warnings",
    subtitle: "Tracking key errors and warnings on all products.",
    image: errorsBg,
  },
};
