import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import SocialsDailyReach from "../components/molecules/SocialsDailyReach";
import AudienceDemographics from "../components/molecules/AudienceDemographics";
import DifferentNumericData from "../components/molecules/DifferentNumericData";
import MainPageOverview from "../components/molecules/MainPageOverview";
import SocialNetworkDistribution from "../components/molecules/SocialNetworkDistribution";
import SocialPageStats from "../components/atoms/SocialPageStats";
import Header from "../components/atoms/Header";

const Socials = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <ComponentsLayout>
          <Header page={"/socials"} />
          <MainPageOverview page={"socials"} />
          <SocialNetworkDistribution />
          <SocialPageStats />
          <SocialsDailyReach />
          <AudienceDemographics />
          <DifferentNumericData />
        </ComponentsLayout>
      </main>
    </>
  );
};

export default Socials;
