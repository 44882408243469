import presentation1 from "../assets/images/icons/presentation1.png";
import presentation2 from "../assets/images/icons/presentation2.png";
import presentation3 from "../assets/images/icons/presentation3.png";
import presentation4 from "../assets/images/icons/presentation1.png";
import presentation5 from "../assets/images/icons/presentation2.png";

export const top5SharedPresentations = [
  {
    name: "Global Farming workshop-Berlin German.PPT",
    image: presentation1,
    event_properties_url: "/dashboard",
  },
  {
    name: "Crypto evolution-California USA.PPT",
    image: presentation2,
    event_properties_url: "/dashboard",
  },
  {
    name: "Techonological advances in genology.PPT",
    image: presentation3,
    event_properties_url: "/dashboard",
  },
  {
    name: "Space and Beyond.PPT",
    image: presentation4,
    event_properties_url: "/dashboard",
  },
  {
    name: "Trade and world markets.PPT",
    image: presentation5,
    event_properties_url: "/dashboard",
  },
];
