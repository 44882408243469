import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as styles from "./styles.module.scss";
import CalendarPicker from "../../atoms/CalendarPicker";
import {
  mainPageOverviewData,
  DashboardPageOverviewData,
} from "../../../constants/mainPageOverviewData";
import { socialsOverviewData } from "../../../constants/socialsImpressionsData";
import PageOverviewCard from "../../atoms/PageOverviewCard";
import upwardArrow from "../../../assets/images/icons/colored-upward-arrow.svg";
import {
  getWebsitePageOverviewDataToday,
  getWebsitePageOverviewData7days,
  getWebsitePageOverviewData30days,
  getWebsitePageOverviewData90days,
} from "../../../state/actions/websitePage.actions";

const MainPageOverview = ({ page }) => {
  const [, setWebsitePageOverviewDataToday] = useState(null);
  const [, setWebsitePageOverviewData7days] = useState(null);
  const [, setWebsitePageOverviewData30days] = useState(null);
  const [, setWebsitePageOverviewData90days] = useState(null);

  const [selectedOptionValue, setSelectedOptionValue] = useState(null);
  const dispatch = useDispatch();

  const handleSelectedOptionChange = (selectedOption) => {
    setSelectedOptionValue(selectedOption);
  };

  const websitePageOverviewDataToday = useSelector(
    (state) => state.websitePageOverviewDataToday.websitePageOverviewDataToday
  );

  const websitePageOverviewData7days = useSelector(
    (state) => state.websitePageOverviewData7days.websitePageOverviewData7days
  );

  const websitePageOverviewData30days = useSelector(
    (state) => state.websitePageOverviewData30days.websitePageOverviewData30days
  );

  const websitePageOverviewData90days = useSelector(
    (state) => state.websitePageOverviewData90days.websitePageOverviewData90days
  );

  useEffect(() => {
    const fetchWebsitePageOverviewDataToday = () => {
      let fetchedWebsitePageOverviewDataToday = async () =>
        await dispatch(getWebsitePageOverviewDataToday());
      setWebsitePageOverviewDataToday(fetchedWebsitePageOverviewDataToday);
    };
    fetchWebsitePageOverviewDataToday();
  }, []);

  useEffect(() => {
    const fetchWebsitePageOverviewData7days = () => {
      let fetchedWebsitePageOverviewData7days = async () =>
        await dispatch(getWebsitePageOverviewData7days());
      setWebsitePageOverviewData7days(fetchedWebsitePageOverviewData7days);
    };
    fetchWebsitePageOverviewData7days();
  }, []);

  useEffect(() => {
    const fetchWebsitePageOverviewData30days = () => {
      let fetchedWebsitePageOverviewData30days = async () =>
        await dispatch(getWebsitePageOverviewData30days());
      setWebsitePageOverviewData30days(fetchedWebsitePageOverviewData30days);
    };
    fetchWebsitePageOverviewData30days();
  }, []);

  useEffect(() => {
    const fetchWebsitePageOverviewData90days = () => {
      let fetchedWebsitePageOverviewData90days = async () =>
        await dispatch(getWebsitePageOverviewData90days());
      setWebsitePageOverviewData90days(fetchedWebsitePageOverviewData90days);
    };
    fetchWebsitePageOverviewData90days();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Overview</h2>
        <div className={styles.dropDowns}>
          <CalendarPicker onSelectedOptionChange={handleSelectedOptionChange} />
        </div>
      </div>
      {page === "link-sharing" ? (
        <div className={styles.overviewCard}>
          {mainPageOverviewData.map((item, index) => (
            <div key={index} className={styles.card}>
              <span className={styles.cardTitle}>{item.title}</span>
              <span className={styles.cardContent}>
                <span className={styles.cardValue}>{item.value}</span>
                {item.increase ? (
                  <span className={`${styles.percentage} ${styles.positive}`}>
                    <img
                      id={styles.arrow}
                      src={upwardArrow}
                      alt="Upward arrow"
                    />
                    {item.increase}
                  </span>
                ) : (
                  <span className={`${styles.percentage} ${styles.negative}`}>
                    {item.decrease}
                  </span>
                )}
              </span>
            </div>
          ))}
        </div>
      ) : page === "website" ? (
        <div className={styles.overviewCard}>
          {selectedOptionValue === "Today" &&
            websitePageOverviewDataToday &&
            Object.keys(websitePageOverviewDataToday).map((item, index) => (
              <div key={index} className={styles.card}>
                <span className={styles.cardTitle}>
                  {(item === "id" && "New Users") ||
                    (item === "total_users" && "Total users") ||
                    (item === "total_sessions" && "Total sessions") ||
                    (item === "avg_session_duration" &&
                      "Average Duration (sec)")}
                </span>
                <span className={styles.cardContent}>
                  <span className={styles.cardValue}>
                    {Math.round(websitePageOverviewDataToday[`${item}`])}
                  </span>
                  {item.increase ? (
                    <span className={`${styles.percentage} ${styles.positive}`}>
                      <img
                        id={styles.arrow}
                        src={upwardArrow}
                        alt="Upward arrow"
                      />
                      {item.increase}
                    </span>
                  ) : (
                    <span className={`${styles.percentage} ${styles.negative}`}>
                      {item.decrease}
                    </span>
                  )}
                </span>
              </div>
            ))}

          {(selectedOptionValue === "7 days ago" ||
            selectedOptionValue === null) &&
            websitePageOverviewData7days &&
            Object.keys(websitePageOverviewData7days).map((item, index) => (
              <div key={index} className={styles.card}>
                <span className={styles.cardTitle}>
                  {(item === "id" && "New Users") ||
                    (item === "total_users" && "Total users") ||
                    (item === "total_sessions" && "Total sessions") ||
                    (item === "avg_session_duration" &&
                      "Average Duration (sec)")}
                </span>
                <span className={styles.cardContent}>
                  <span className={styles.cardValue}>
                    {Math.round(websitePageOverviewData7days[`${item}`])}
                  </span>
                  {item.increase ? (
                    <span className={`${styles.percentage} ${styles.positive}`}>
                      <img
                        id={styles.arrow}
                        src={upwardArrow}
                        alt="Upward arrow"
                      />
                      {item.increase}
                    </span>
                  ) : (
                    <span className={`${styles.percentage} ${styles.negative}`}>
                      {item.decrease}
                    </span>
                  )}
                </span>
              </div>
            ))}

          {selectedOptionValue === "30 days ago" &&
            websitePageOverviewData30days &&
            Object.keys(websitePageOverviewData30days).map((item, index) => (
              <div key={index} className={styles.card}>
                <span className={styles.cardTitle}>
                  {(item === "id" && "New Users") ||
                    (item === "total_users" && "Total users") ||
                    (item === "total_sessions" && "Total sessions") ||
                    (item === "avg_session_duration" &&
                      "Average Duration (sec)")}
                </span>
                <span className={styles.cardContent}>
                  <span className={styles.cardValue}>
                    {Math.round(websitePageOverviewData30days[`${item}`])}
                  </span>
                  {item.increase ? (
                    <span className={`${styles.percentage} ${styles.positive}`}>
                      <img
                        id={styles.arrow}
                        src={upwardArrow}
                        alt="Upward arrow"
                      />
                      {item.increase}
                    </span>
                  ) : (
                    <span className={`${styles.percentage} ${styles.negative}`}>
                      {item.decrease}
                    </span>
                  )}
                </span>
              </div>
            ))}

          {selectedOptionValue === "90 days ago" &&
            websitePageOverviewData90days &&
            Object.keys(websitePageOverviewData90days).map((item, index) => (
              <div key={index} className={styles.card}>
                <span className={styles.cardTitle}>
                  {(item === "id" && "New Users") ||
                    (item === "total_users" && "Total users") ||
                    (item === "total_sessions" && "Total sessions") ||
                    (item === "avg_session_duration" &&
                      "Average Duration (sec)")}
                </span>
                <span className={styles.cardContent}>
                  <span className={styles.cardValue}>
                    {Math.round(websitePageOverviewData90days[`${item}`])}
                  </span>
                  {item.increase ? (
                    <span className={`${styles.percentage} ${styles.positive}`}>
                      <img
                        id={styles.arrow}
                        src={upwardArrow}
                        alt="Upward arrow"
                      />
                      {item.increase}
                    </span>
                  ) : (
                    <span className={`${styles.percentage} ${styles.negative}`}>
                      {item.decrease}
                    </span>
                  )}
                </span>
              </div>
            ))}
        </div>
      ) : page === "socials" ? (
        <div className={styles.overviewCard}>
          {socialsOverviewData.map((item, index) => (
            <div key={index} className={styles.card}>
              <span className={styles.cardTitle}>{item.description}</span>
              <span className={styles.cardContent}>
                <span className={styles.cardValue}>{item.value}</span>
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.cards}>
          {DashboardPageOverviewData.map((item, index) => (
            <div key={index}>
              <PageOverviewCard
                icon={item["icon"]}
                title={item["title"]}
                value={item["value"]}
                percentage={item["percentage"]}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MainPageOverview;
