import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import CalendarPicker from "../../atoms/CalendarPicker";
import {
  social_network_data,
  tooltipLabels,
} from "../../../constants/socialNetworkData";
import Spinner from "../../atoms/Spinner";
import SocialNetworkChart from "../../atoms/SocialNetworkChart";

const SocialNetworkDistribution = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Social Network Distribution</h2>
        <div className={styles.calendar}>
          <CalendarPicker />
        </div>
      </div>
      <h3>Summary of social network distribution usage</h3>
      {social_network_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      {social_network_data.length > 0 &&
        social_network_data.map((i) => i !== undefined) && (
          <SocialNetworkChart
            data={social_network_data}
            tooltipLabels={tooltipLabels}
            isMobile={isMobile}
          />
        )}
    </div>
  );
};

export default SocialNetworkDistribution;
