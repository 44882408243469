import React from "react";
import PercentageCircle from "../../atoms/PercentageCircle";
import { colors } from "../../../constants/top10CompaniesColors";
import * as styles from "./styles.module.scss";

const ActiveCompany = ({ info }) => {
  const pathColor = colors[0].pathColor;
  const trailColor = colors[0].trailColor;

  return (
    <div className={styles.container}>
      <PercentageCircle
        percentage={info.percentage}
        pathColor={pathColor}
        trailColor={trailColor}
      />
      <div className={styles.name}>{info.name}</div>
      <div className={styles.type}>{info.type}</div>
    </div>
  );
};

export default ActiveCompany;
