import React from "react";
import * as styles from "./styles.module.scss";

const SocialPageStatsCard = ({ info }) => {
  return (
    <div className={styles.container}>
      <div className={styles.palletOne}>
        <p className={styles.value}>{info["valueOne"]}</p>
        <p className={styles.description}>{info["descriptionOne"]}</p>
      </div>
      <div className={styles.palletTwo}>
        <p className={styles.value}>{info["valueTwo"]}</p>
        <p className={styles.description}>{info["descriptionTwo"]}</p>
      </div>
    </div>
  );
};

export default SocialPageStatsCard;
