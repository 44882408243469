import React from "react";
import toggle from "../../../assets/images/icons/toggle-arrow.png";
import toggleMobile from "../../../assets/images/icons/toggle-mobile.svg";
import closeBtn from "../../../assets/images/icons/close.png";

import * as styles from "./styles.module.scss";

const Toggle = ({ onClick, expanded, mobile }) => {
  return (
    <div
      className={`${styles.container} ${expanded ? styles.expanded : ""}`}
      onClick={onClick}
    >
      {mobile ? (
        <img src={expanded ? closeBtn : toggleMobile} alt="Navbar Toggle" />
      ) : (
        <img src={toggle} alt="Navbar Toggle" />
      )}
    </div>
  );
};

export default Toggle;
