import { GET_TOP_ACTIVE_COMPANIES } from "../../constants/actionTypes";

const initialState = {
  topActiveCompanies: [],
  errors: {},
};

export const topActiveCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_ACTIVE_COMPANIES:
      return {
        ...state,
        topActiveCompanies: action.payload,
      };
    default:
      return state;
  }
};
