export const eventTypes = [
  {
    title: "MW Solution",
    event: "mwSolutionEvents",
    count: 345,
  },
  {
    title: "Direct Login",
    event: "directLoginEvents",
    count: 134,
  },
  {
    title: "Dashboard",
    event: "dashboardEvents",
    count: 79,
  },
  {
    title: "User Link",
    event: "userLinkEventsEvents",
    count: 288,
  },
];
