import {
  GET_WEBSITE_PAGE_OVERVIEW_DATA_TODAY,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_7DAYS,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_30DAYS,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_90DAYS,
  GET_WEBSITE_PAGE_OVERVIEW_DATA_365DAYS,
  GET_WEBSITE_MOST_VISITED_PAGES_DATA_365DAYS,
} from "../../constants/actionTypes";
import { API_PROXY_URL } from "../../api/api";

const API_HOST_URL = API_PROXY_URL;

export const getWebsitePageOverviewDataToday = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/website_page_overview_today`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((websitePageOverviewDataToday) => {
      dispatch({
        type: GET_WEBSITE_PAGE_OVERVIEW_DATA_TODAY,
        payload: websitePageOverviewDataToday["results"][1],
      });
    });
};

export const getWebsitePageOverviewData7days = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/website_page_overview_7days`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((websitePageOverviewData7days) => {
      dispatch({
        type: GET_WEBSITE_PAGE_OVERVIEW_DATA_7DAYS,
        payload: websitePageOverviewData7days["results"][1],
      });
    });
};

export const getWebsitePageOverviewData30days = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/website_page_overview_30days`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((websitePageOverviewData30days) => {
      dispatch({
        type: GET_WEBSITE_PAGE_OVERVIEW_DATA_30DAYS,
        payload: websitePageOverviewData30days["results"][1],
      });
    });
};

export const getWebsitePageOverviewData90days = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/website_page_overview_90days`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((websitePageOverviewData90days) => {
      dispatch({
        type: GET_WEBSITE_PAGE_OVERVIEW_DATA_90DAYS,
        payload: websitePageOverviewData90days["results"][1],
      });
    });
};

export const getWebsitePageOverviewData365days = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/website_page_overview_365days`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((websitePageOverviewData365days) => {
      dispatch({
        type: GET_WEBSITE_PAGE_OVERVIEW_DATA_365DAYS,
        payload: websitePageOverviewData365days["results"][1],
      });
    });
};

export const getWebsiteMostVisitedPages365days = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/most_visited_pages_365days`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((websiteMostVisitedPagesData365days) => {
      dispatch({
        type: GET_WEBSITE_MOST_VISITED_PAGES_DATA_365DAYS,
        payload: websiteMostVisitedPagesData365days,
      });
    });
};
