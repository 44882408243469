import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import GenderDistribution from "../components/molecules/Gender Distribution";
import UsersPageOverview from "../components/molecules/UsersPageOverview";
import AgeDemographics from "../components/molecules/AgeDemographics";
import UserTypes from "../components/molecules/UserTypes";
import Header from "../components/atoms/Header";
import MostFrequentCompanies from "../components/molecules/MostFrequentCompanies";

const Users = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <ComponentsLayout>
          <Header page={"/users"} />
          <UsersPageOverview />
          <UserTypes />
          <AgeDemographics />
          <GenderDistribution />
          <MostFrequentCompanies />
        </ComponentsLayout>
      </main>
    </>
  );
};

export default Users;
