import React from "react";
import * as styles from "./styles.module.scss";
import MostFrequentCompaniesTable from "../../organisms/MostFrequentCompaniesTable";
import CalendarPicker from "../../atoms/CalendarPicker";
import searchIcon from "../../../assets/images/icons/search.png";

const MostFrequentCompanies = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h2>Most frequent companies</h2>
        </div>
        <form className={styles.search}>
          <button type="submit">
            <img src={searchIcon} alt="Search Icon" />
          </button>
          <input
            type="text"
            placeholder="Type organisation name"
            name="search"
          />
        </form>
        <div className={styles.dropDowns}>
          <CalendarPicker />
        </div>
      </div>
      <MostFrequentCompaniesTable />
    </div>
  );
};

export default MostFrequentCompanies;
