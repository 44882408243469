export const data = [
  { iso3: "BLR", count: 75, country: "Belarus", percentage: "12%" },
  { iso3: "RWA", count: 88, country: "Rwanda", percentage: "14%" },
  { iso3: "TLS", count: 43, country: "Timor-Leste", percentage: "7%" },
  { iso3: "TKM", count: 19, country: "Turkmenistan", percentage: "3%" },
  { iso3: "NOR", count: 60, country: "Norway", percentage: "9%" },
  { iso3: "FRA", count: 4, country: "France", percentage: "1%" },
  { iso3: "GBR", count: 38, country: "United Kingdom", percentage: "6%" },
  { iso3: "UKR", count: 2, country: "Ukraine", percentage: "0.3%" },
  { iso3: "KEN", count: 60, country: "Kenya", percentage: "9.3%" },
  { iso3: "CHN", count: 57, country: "China", percentage: "9%" },
  { iso3: "CAN", count: 59, country: "Canada", percentage: "9%" },
  { iso3: "DNK", count: 24, country: "Denmark", percentage: "3.7%" },
  { iso3: "BRA", count: 21, country: "Brazil", percentage: "3.2%" },
];
