import React from "react";
import * as styles from "./styles.module.scss";
import { PieChart, Pie, Cell, Tooltip, Label } from "recharts";

const COLORS = ["#0693DA", "#00A480"];

const PieChartGenderDistribution = ({ data }) => {
  const total_users = data[0]["count"] + data[1]["count"];

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={styles.customTooltip}>
          {payload.map((item) => {
            return (
              <div className={styles.inner} key={item["name"]}>
                <p className={styles.toolTipHeader}>
                  <span>{item["name"]}</span>
                </p>
                <p className={styles.toolTipText}>
                  <span style={{ color: item["payload"]["fill"] }}>
                    {item["value"]} %
                  </span>
                </p>
                <p className={styles.pointer}></p>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <PieChart width={300} height={978}>
      <Tooltip
        content={<CustomTooltip />}
        cursor={false}
        allowEscapeViewBox={{ x: true, y: true }}
        offset={6}
      />

      <Pie
        data={data}
        cy="13%"
        cx="47.5%"
        innerRadius={75}
        outerRadius={100}
        fill="#8884d8"
        paddingAngle={0.0}
        dataKey="percentage"
        stroke={{ width: 0.0 }}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label
          width={10}
          position="center"
          fill="#fff"
          className={styles.label}
        >
          {"Total " + `${total_users}`}
        </Label>
      </Pie>
    </PieChart>
  );
};

export default PieChartGenderDistribution;
