import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import Header from "../components/atoms/Header";
import ErrorsWarningsTable from "../components/molecules/ErrorsWarningsTable";
import ErrorWarningsSelector from "../components/molecules/ErrorsWarningsSelector";

const ErrorsWarnings = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <ComponentsLayout>
          <Header page={"/errors-warnings"} />
          <ErrorWarningsSelector />
          <ErrorsWarningsTable />
        </ComponentsLayout>
      </main>
    </>
  );
};

export default ErrorsWarnings;
