import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import CalendarPicker from "../../atoms/CalendarPicker";
import {
  age_demographics_data,
  tooltipLabels,
} from "../../../constants/ageDemographicsData";
import { socials } from "../../../constants/socials";
import DropDown from "../../atoms/DropDown";
import Spinner from "../../atoms/Spinner";
import AgeBarChart from "../../atoms/AgeBarChart";

const AudienceDemographics = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Audience Demographics</h2>
        <div className={styles.legend}>
          <span>Total</span>
          <span>Male</span>
          <span>Female</span>
        </div>
        <div className={styles.calendar}>
          <CalendarPicker />
        </div>
        <div className={styles.dropDown}>
          <DropDown title={socials[0]["name"]} data={socials} />
        </div>
      </div>
      {age_demographics_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      {age_demographics_data.length > 0 &&
        age_demographics_data.map((i) => i !== undefined) && (
          <AgeBarChart
            data={age_demographics_data}
            tooltipLabels={tooltipLabels}
            isMobile={isMobile}
          />
        )}
    </div>
  );
};

export default AudienceDemographics;
