import React, { useEffect, useState } from "react";
import OrganisationPresentation from "../../molecules/OrganisationPresentation";
import { top5SharedPresentations } from "../../../constants/top5SharedPresentations";
import CalendarPicker from "../../atoms/CalendarPicker";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import * as styles from "./styles.module.scss";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";

const Top5SharedPresentations = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 992;
  const isTablet = width > 992 && width <= 1024;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Top 5 Shared Presentations</h2>
        <h3>
          Summary of top 5 most shared presentations by different Organisations
        </h3>
        {/* <button>View All</button> */}
        <div className={styles.dropDowns}>
          <CalendarPicker />
        </div>
      </div>
      {!isMobile ? (
        <Swiper
          spaceBetween={isTablet ? 48 : 96}
          slidesPerView={isTablet ? 3 : 5}
          modules={[Navigation]}
          navigation
          className={styles.carousel}
        >
          {!top5SharedPresentations
            ? ""
            : top5SharedPresentations.map((sharedPresentation, index) => (
                <SwiperSlide key={index}>
                  <OrganisationPresentation data={sharedPresentation} />
                </SwiperSlide>
              ))}
        </Swiper>
      ) : (
        <div className={styles.mobileContainer}>
          {!top5SharedPresentations
            ? ""
            : top5SharedPresentations.map((sharedPresentation, index) => (
                <OrganisationPresentation
                  key={index}
                  data={sharedPresentation}
                />
              ))}
        </div>
      )}
    </div>
  );
};

export default Top5SharedPresentations;
