export const socialsOverviewData = [
  {
    value: "54k",
    description: "Daily viral impressions",
  },
  {
    value: "1k",
    description: "Daily viral reach",
  },
  {
    value: "50k",
    description: "Daily total organic views",
  },
  {
    value: "4k",
    description: "Daily reach of page costs",
  },
];
