// Layout Types
import DefaultLayout from "./components/organisms/Layout";
import LandingLayout from "./components/organisms/LandingLayout";

// Route Views
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import LinkSharing from "./pages/LinkSharing";
import Website from "./pages/Website";
import Socials from "./pages/Socials";
import ErrorsWarnings from "./pages/ErrorsWarnings";
import Library from "./pages/Library";
import Studio from "./pages/Studio";
import Connect from "./pages/Connect";

export const routes = [
  {
    path: "/",
    exact: true,
    layout: LandingLayout,
    component: LandingPage,
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users,
  },
  {
    path: "/link-sharing",
    layout: DefaultLayout,
    component: LinkSharing,
  },
  {
    path: "/library",
    layout: DefaultLayout,
    component: Library,
  },
  {
    path: "/studio",
    layout: DefaultLayout,
    component: Studio,
  },
  {
    path: "/connect",
    layout: DefaultLayout,
    component: Connect,
  },
  {
    path: "/website",
    layout: DefaultLayout,
    component: Website,
  },
  {
    path: "/socials",
    layout: DefaultLayout,
    component: Socials,
  },
  {
    path: "/errors-warnings",
    layout: DefaultLayout,
    component: ErrorsWarnings,
  },
];
