import React from "react";
import * as styles from "./styles.module.scss";
// import CalendarPicker from "../../atoms/CalendarPicker";
import UsersPageOverviewCard from "../../atoms/UsersPageOverviewCard";
import { usersPageOverviewData } from "../../../constants/usersPageOverviewData";

const UsersPageOverview = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {/* <div className={styles.dropDowns}>
          <CalendarPicker />
        </div> */}
        <h2>Overview</h2>
      </div>
      <div className={styles.cards}>
        {usersPageOverviewData.map((item, index) => (
          <div key={index}>
            <UsersPageOverviewCard
              title={item["title"]}
              value={item["value"]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsersPageOverview;
