import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import OverviewOfPresentations from "../components/molecules/OverviewOfPresentations";
import IndividualVsOrganizations from "../components/atoms/IndividualVsOrganizations";
import MainPageOverview from "../components/molecules/MainPageOverview";
import Top5SharedPresentations from "../components/organisms/Top5SharedPresentations";
import Top10ActiveCompanies from "../components/organisms/Top10ActiveCompanies";
import EventsType from "../components/atoms/EventsType";
import Header from "../components/atoms/Header";
import BrowserEngagements from "../components/molecules/BrowserEngagements";

const LinkSharing = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <ComponentsLayout>
          <Header page={"/link-sharing"} />
          <MainPageOverview page={"link-sharing"} />
          <OverviewOfPresentations />
          <IndividualVsOrganizations />
          <BrowserEngagements />
          <EventsType />
          <Top10ActiveCompanies />
          <Top5SharedPresentations />
        </ComponentsLayout>
      </main>
    </>
  );
};

export default LinkSharing;
