import React from "react";
import * as styles from "./styles.module.scss";
import { formatDate } from "../../../helpers/calendarOptions";

const CalendarSection = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.sectionButtons}>
        <div onClick={() => props.onChange(false, false)}>Cancel</div>
        <div onClick={() => props.onChange(false, false)}>Apply</div>
      </div>

      <div className={styles.dateRange}>
        <div>{formatDate(props.startDate)}</div>
        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
        <div>{formatDate(props.endDate)}</div>
      </div>
    </div>
  );
};

export default CalendarSection;
