import React from "react";
import * as styles from "./styles.module.scss";

const PageOverviewCard = ({ icon, title, value, percentage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.icons}>
          <img src={icon} alt="Icon" />
        </div>
        <div className={styles.title}>{title}</div>
        <h2 className={styles.value}>{value}</h2>
        <div className={styles.footer}>
          <span className={styles.percentageIncrease}> {percentage}</span>
          <span className={styles.period}> this week</span>
        </div>
        {/* <div className={styles.increaseIcon}>
          <p className={styles.quarterCircleTopRight}></p>
          <p className={styles.quarterCircleBottomLeft}></p>
        </div> */}
      </div>
    </div>
  );
};

export default PageOverviewCard;
