export const HEALTHCHECK_REQUEST = "HEALTHCHECK_REQUEST";
export const HEALTHCHECK_SUCCESS = "HEALTHCHECK_SUCCESS";
export const HEALTHCHECK_FAILURE = "HEALTHCHECK_FAILURE";
export const GET_SHOWROOM_EVENTS = "GET_SHOWROOM_EVENTS";
export const GET_LIBRARY_EVENTS = "GET_LIBRARY_EVENTS";
export const GET_COVER_PAGE_EVENTS = "GET_COVER_PAGE_EVENTS";
export const GET_PRESENTATION_EVENTS = "GET_PRESENTATION_EVENTS";
export const GET_KENYA_ENGAGEMENTS = "GET_KENYA_ENGAGEMENTS";
export const GET_USA_ENGAGEMENTS = "GET_USA_ENGAGEMENTS";
export const GET_UGANDA_ENGAGEMENTS = "GET_UGANDA_ENGAGEMENTS";
export const GET_NORWAY_ENGAGEMENTS = "GET_NORWAY_ENGAGEMENTS";
export const GET_CHROME_ENGAGEMENTS = "GET_CHROME_ENGAGEMENTS";
export const GET_FIREFOX_ENGAGEMENTS = "GET_FIREFOX_ENGAGEMENTS";
export const GET_OPERA_ENGAGEMENTS = "GET_OPERA_ENGAGEMENTS";
export const GET_EDGE_ENGAGEMENTS = "GET_EDGE_ENGAGEMENTS";
export const GET_SAFARI_ENGAGEMENTS = "GET_SARARI_ENGAGEMENTS";
export const GET_INDIVIDUALS = "GET_INDIVIDUALS";
export const GET_ORGANISATIONS = "GET_ORGANISATIONS";
export const GET_TOP_SHARED_EVENTS = "GET_TOP_SHARED_EVENTS";
export const GET_TOP_ACTIVE_COMPANIES = "GET_TOP_ACTIVE_COMPANIES";
export const GET_USERS = "GET_USERS";
export const GET_SESSIONS = "GET_SESSIONS";
export const GET_DIRECT_TRAFFIC = "GET_DIRECT_TRAFFIC";
export const GET_COUNTRY_DISTRIBUTION = "GET_COUNTRY_DISTRIBUTION";
export const GET_MOST_FREQUENT_USERS = "GET_MOST_FREQUENT_USERS";
export const GET_NEW_AND_RETURNING_USERS = "GET_NEW_AND_RETURNING_USERS";
export const GET_USERS_TABLE_DATA = "GET_USERS_TABLE_DATA";
export const GET_EXPORT_DATA = "GET EXPORT DATA";
export const GET_WEBSITE_PAGE_OVERVIEW_DATA_TODAY =
  "GET_WEBSITE_PAGE_OVERVIEW_DATA_TODAY";
export const GET_WEBSITE_PAGE_OVERVIEW_DATA_7DAYS =
  "GET_WEBSITE_PAGE_OVERVIEW_DATA_7DAYS";
export const GET_WEBSITE_PAGE_OVERVIEW_DATA_30DAYS =
  "GET_WEBSITE_PAGE_OVERVIEW_DATA_30DAYS";
export const GET_WEBSITE_PAGE_OVERVIEW_DATA_90DAYS =
  "GET_WEBSITE_PAGE_OVERVIEW_DATA_90DAYS";
export const GET_WEBSITE_PAGE_OVERVIEW_DATA_365DAYS =
  "GET_WEBSITE_PAGE_OVERVIEW_DATA_365DAYS";
export const GET_WEBSITE_MOST_VISITED_PAGES_DATA_365DAYS =
  "GET_WEBSITE_MOST_VISITED_PAGES_DATA_365DAYS";
