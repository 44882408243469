import React from "react";
import logo from "../../../assets/images/icons/small-logo.png";

import * as styles from "./styles.module.scss";

const Logo = () => {
  return (
    <div className={`${styles.container} `}>
      <div className={styles.logo}>
        <img src={logo} alt="MasterWiZR Logo" />
      </div>
      <div className={styles.text}>
        <span>WiZR</span> Analytics
      </div>
    </div>
  );
};

export default Logo;
