import { GET_MOST_FREQUENT_USERS } from "../../constants/actionTypes";

const initialState = {
  mostFrequentUsers: [],
  errors: {},
};

export const mostFrequentUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOST_FREQUENT_USERS:
      return {
        ...state,
        mostFrequentUsers: action.payload,
      };
    default:
      return state;
  }
};
