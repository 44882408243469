export const data = [
  {
    name: "CocaCola Zero",
    type: "Marketing strategy",
    percentage: 89,
  },
  {
    name: "GM MOTORS New",
    type: "wheels promo",
    percentage: 75,
  },
  {
    name: "Covid 19 Awareness",
    type: "Program 2021 Mo..",
    percentage: 59,
  },
  {
    name: "Black and White",
    type: "Convesion 2021",
    percentage: 64,
  },
  {
    name: "Company Collection",
    type: "Chanels by Jim...",
    percentage: 91,
  },
  {
    name: "CocaCola Zero",
    type: "Marketing strategy",
    percentage: 89,
  },
  {
    name: "GM MOTORS New",
    type: "wheels promo",
    percentage: 75,
  },
  {
    name: "Covid 19 Awareness",
    type: "Program 2021 Mo..",
    percentage: 59,
  },
  {
    name: "Black and White",
    type: "Convesion 2021",
    percentage: 64,
  },
  {
    name: "Company Collection",
    type: "Chanels by Jim...",
    percentage: 91,
  },
];
