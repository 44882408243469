import React from "react";
import PropTypes from "prop-types";
import * as styles from "./styles.module.scss";

const ComponentsLayout = ({ children, page }) => (
  <div
    className={`${styles.container} ${
      page == "users"
        ? styles.containerUsersPage
        : page === "error-warnings"
        ? styles.containerErrorsWarningsPage
        : ""
    }`}
  >
    {children}
  </div>
);

ComponentsLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

export default ComponentsLayout;
