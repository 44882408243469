import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import WebsiteTable from "../components/organisms/WebsiteTable";
import SocialFlexTable from "../components/molecules/SocialFlex";
import NewUsersByChannel from "../components/molecules/NewUsersByChannel";
import Header from "../components/atoms/Header";
import MainPageOverview from "../components/molecules/MainPageOverview";
import KeywordSearchTraffic from "../components/organisms/KeywordSearchTraffic";

const Website = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <Header page={"/website"} />
        <MainPageOverview page={"website"} />
        <WebsiteTable />
        <NewUsersByChannel />
        <SocialFlexTable />
        <KeywordSearchTraffic />
        <ComponentsLayout></ComponentsLayout>
      </main>
    </>
  );
};

export default Website;
