import React from "react";
import MonthlyCalendar from "../../atoms/MonthlyCalendar";
import * as styles from "./styles.module.scss";

const CalendarContainer = ({ onChange }) => {
  return (
    <div className={styles.container}>
      <MonthlyCalendar onChange={onChange} />
      <div className={styles.vl}></div>
    </div>
  );
};

export default CalendarContainer;
