import {
  GET_INDIVIDUALS,
  GET_ORGANISATIONS,
} from "../../constants/actionTypes";

const API_HOST_URL = process.env.API_HOST_URL;

export const getIndividuals = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/users/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((individuals) => {
      dispatch({
        type: GET_INDIVIDUALS,
        payload: individuals,
      });
    });
};

export const getOrganisations = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/organizations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((organisations) => {
      dispatch({
        type: GET_ORGANISATIONS,
        payload: organisations,
      });
    });
};
