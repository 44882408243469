import countriesImg from "../assets/images/icons/icon-location.svg";
import portalsImg from "../assets/images/icons/icon-portals.svg";
import presentationsImg from "../assets/images/icons/icon-presentations-white.svg";
import avgTimeImg from "../assets/images/icons/icon-timer-white.svg";
import usersImg from "../assets/images/icons/icon-users-white.svg";

import countriesGraphImg from "../assets/images/background/5.png";
import portalsGraphImg from "../assets/images/background/4.png";
import presentationsGraphImg from "../assets/images/background/3.png";
import avgTimeGraphImg from "../assets/images/background/2.png";
import usersGraphImg from "../assets/images/background/1.png";

export const landingInfo = [
  {
    title: "Countries",
    number: 50,
    image: countriesImg,
    graph: countriesGraphImg,
  },
  {
    title: "Portals",
    number: 15,
    image: portalsImg,
    graph: portalsGraphImg,
  },
  {
    title: "Presentations",
    number: 32,
    image: presentationsImg,
    graph: presentationsGraphImg,
  },
  {
    title: "Average",
    number: "5sec",
    image: avgTimeImg,
    graph: avgTimeGraphImg,
  },
  {
    title: "Users",
    number: 400,
    image: usersImg,
    graph: usersGraphImg,
  },
];
