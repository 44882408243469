export const tooltipLabels = [
  {
    key: "percentage_avg_reach",
    label: "Avg Reach",
  },
  {
    key: "daily_reach_percentage",
    label: "Daily Reach",
  },
  {
    key: "impressions_percentage",
    label: "Impressions",
  },
];

export const socials_data = [
  {
    name: "Mon - Oct 20",
    daily_reach: 801,
    impressions: 397,
    daily_reach_percentage: 67,
    impressions_percentage: 33,
    percentage_avg_reach: 1,
  },
  {
    name: "Tue - Oct 21",
    daily_reach: 4010,
    impressions: 710,
    daily_reach_percentage: 85,
    impressions_percentage: 15,
    percentage_avg_reach: 30,
  },
  {
    name: "Wed - Oct 22",
    daily_reach: 190,
    impressions: 2701,
    daily_reach_percentage: 7,
    impressions_percentage: 93,
    percentage_avg_reach: 18,
  },
  {
    name: "Thu - Oct 23",
    daily_reach: 7701,
    impressions: 8000,
    daily_reach_percentage: 49,
    impressions_percentage: 51,
    percentage_avg_reach: 98,
  },
  {
    name: "Fri - Oct 24",
    daily_reach: 5006,
    impressions: 100,
    daily_reach_percentage: 98,
    impressions_percentage: 2,
    percentage_avg_reach: 32,
  },
  {
    name: "Sat - Oct 25",
    daily_reach: 201,
    impressions: 783,
    daily_reach_percentage: 20,
    impressions_percentage: 80,
    percentage_avg_reach: 6,
  },
  {
    name: "Sun - Oct 26",
    daily_reach: 781,
    impressions: 5000,
    daily_reach_percentage: 14,
    impressions_percentage: 86,
    percentage_avg_reach: 36,
  },
];
