import React from "react";
import PropTypes from "prop-types";
import * as styles from "./styles.module.scss";
import Logo from "../../atoms/Logo";

const LandingHeaderLayout = ({ children }) => {
  return (
    <div>
      <div className={styles.container}>
        {children}
        <Logo />
      </div>
    </div>
  );
};

LandingHeaderLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

export default LandingHeaderLayout;
