import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import CalendarSection from "../CalendarSection";
import { days, months } from "../../../constants/calendar";
import "react-calendar/dist/Calendar.css";
import "./styles.scss";

const MonthlyCalendar = ({ onChange }) => {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const allData = () => {
      if (date.length > 0) {
        setStartDate(date[0]);
        setEndDate(date[1]);
      }
    };
    allData();
  }, [date, startDate, endDate]);

  const formatShortWeekday = (_, value) => {
    return days[value.getDay()];
  };

  const formatMonthYear = (_, value) => {
    return [months[value.getMonth()], value.getFullYear()];
  };

  return (
    <div className="app">
      <div className="calendar-container">
        <Calendar
          next2Label={null}
          prev2Label={null}
          showNavigation={true}
          showNeighboringMonth={false}
          showDoubleView={true}
          onChange={setDate}
          showFixedNumberOfWeeks={true}
          selectRange={true}
          formatShortWeekday={formatShortWeekday}
          formatMonthYear={formatMonthYear}
        />
        <CalendarSection
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default MonthlyCalendar;
