import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { landingInfo } from "../../../constants/landingInfo";
const Landing = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.content}>
        <h3>The power of knowing</h3>
        <h1>WiZR Board</h1>
        <p>
          Powerful, self-serve product analytics to help you convert, engage,
          and retain more users.
        </p>
        <Link className={styles.link} to="/dashboard">
          Get Started
        </Link>
      </div>

      <div className={styles.graph}>
        {landingInfo.map((item, i) => {
          return (
            <div key={i} className={styles.item}>
              <div className={styles.category} id={styles[`${item.title}`]}>
                <img src={item.image} alt="icon" />
                <h3>{item.title}</h3>
                <h2>{item.number}</h2>
              </div>
              <img src={item.graph} alt="Graph" id={styles.bar} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Landing;
