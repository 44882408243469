import React from "react";
import "regenerator-runtime/runtime";
import PieChartGenderDistribution from "../../atoms/PieChartGenderDistribution";
import CalendarPicker from "../../atoms/CalendarPicker";
import Dropdown from "../../atoms/DropDown";
import { packages_data } from "../../../constants/packages";
import { gender_data } from "../../../constants/gender";
import * as styles from "./styles.module.scss";

const GenderDistribution = () => {
  const female_percentage = gender_data[1]["percentage"];
  const male_percentage = gender_data[0]["percentage"];
  const num_female = gender_data[1]["count"];
  const num_male = gender_data[0]["count"];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>
          Gender <br></br>Distribution
        </h2>
        <div className={styles.calender}>
          <CalendarPicker component={"Gender Distribution"} />
        </div>
        <div className={styles.dropDown}>
          <Dropdown title={packages_data[0]["name"]} data={packages_data} />
        </div>
        <div className={styles.legend}>
          <span>
            Female <br></br> {female_percentage} % ({num_female})
          </span>
          <span>
            Male <br></br> {male_percentage} % ({num_male})
          </span>
        </div>
        <div className={styles.pieChart}>
          <PieChartGenderDistribution data={gender_data} />
        </div>
      </div>
    </div>
  );
};

export default GenderDistribution;
