import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as styles from "./styles.module.scss";
import SearchBar from "../../atoms/SearchBar";
import ExportButton from "../../atoms/ExportButton";
import Logo from "../../atoms/Logo";

const HeaderLayout = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;
  return (
    <div>
      {!isMobile ? (
        <div className={styles.container}>
          {children}
          <Logo />
          <div className={styles.header}>
            <SearchBar />
            <ExportButton />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

HeaderLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

export default HeaderLayout;
