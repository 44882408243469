import {
  GET_CHROME_ENGAGEMENTS,
  GET_FIREFOX_ENGAGEMENTS,
  GET_OPERA_ENGAGEMENTS,
  GET_EDGE_ENGAGEMENTS,
  GET_SAFARI_ENGAGEMENTS,
} from "../../constants/actionTypes";

const API_HOST_URL = process.env.API_HOST_URL;

export const getChromeEngagements = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/events?os_name=Chrome`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((chromeEngagements) => {
      dispatch({
        type: GET_CHROME_ENGAGEMENTS,
        payload: chromeEngagements,
      });
    });
};

export const getFirefoxEngagements = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/events?os_name=Firefox`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((firefoxEngagements) => {
      dispatch({
        type: GET_FIREFOX_ENGAGEMENTS,
        payload: firefoxEngagements,
      });
    });
};

export const getOperaEngagements = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/events?os_name=Opera`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((operaEngagements) => {
      dispatch({
        type: GET_OPERA_ENGAGEMENTS,
        payload: operaEngagements,
      });
    });
};

export const getEdgeEngagements = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/events?os_name=Edge`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((edgeEngagements) => {
      dispatch({
        type: GET_EDGE_ENGAGEMENTS,
        payload: edgeEngagements,
      });
    });
};

export const getSafariEngagements = () => (dispatch) => {
  return fetch(`${API_HOST_URL}/events?os_name=Safari`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((safariEngagements) => {
      dispatch({
        type: GET_SAFARI_ENGAGEMENTS,
        payload: safariEngagements,
      });
    });
};
