import React from "react";
import "regenerator-runtime/runtime";
import PieChartWithPaddingAngle from "../../atoms/PieChartWithPaddingAngle";
import CalendarPicker from "../../atoms/CalendarPicker";
import { packages_data } from "../../../constants/packages";
import * as styles from "./styles.module.scss";

const PackagesBoughtByClients = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Packages Sold</h2>
        <div className={styles.calender}>
          <CalendarPicker />
        </div>
        <div className={styles.legend}>
          <PieChartWithPaddingAngle data={packages_data} />
          <div className={styles.legendContainer}>
            <span>Package 1</span>
            <span>Package 2</span>
            <span>Package 3</span>
            <span>Package 4</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagesBoughtByClients;
