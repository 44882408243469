export const social_page_stats_data = [
  {
    descriptionOne: "Weekly Video Views",
    valueOne: "1M",
    descriptionTwo: "Weekly Followers",
    valueTwo: "4K",
  },
  {
    descriptionOne: "Monthly Video Views",
    valueOne: "2M",
    descriptionTwo: "Monthly Followers",
    valueTwo: "8K",
  },
  {
    descriptionOne: "Yearly Video Views",
    valueOne: "10M",
    descriptionTwo: "Yearly Followers",
    valueTwo: "15K",
  },

  {
    descriptionOne: "Weekly  Post Likes",
    valueOne: "1K",
    descriptionTwo: "Weekly Posts",
    valueTwo: "3K",
  },
];
