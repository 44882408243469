import React from "react";
import * as styles from "./styles.module.scss";

const ProgressBar = ({ percentage }) => {
  return (
    <div className={styles.progressBar}>
      <div className={styles.filler} style={{ width: `${percentage}%` }}>
        {" "}
      </div>
    </div>
  );
};

export default ProgressBar;
