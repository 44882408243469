export const modules = [
  {
    name: "Library",
    id: 1,
  },
  {
    name: "Studio",
    id: 2,
  },
  {
    name: "Connect",
    id: 3,
  },
];
