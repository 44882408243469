import React from "react";
import * as styles from "./styles.module.scss";
import ChoroplethMap from "../../atoms/ChoroplethMap";
import CountriesTable from "../../organisms/CountriesTable";
import CalendarPicker from "../../atoms/CalendarPicker";
import { data } from "../../../constants/countryDistribution";

const CountryDistribution = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h2>Country distribution</h2>
          <h3>Summary of user demography</h3>
        </div>
        <div className={styles.dropDowns}>
          <CalendarPicker />
        </div>
      </div>
      <CountriesTable />
      <ChoroplethMap data={data} />
    </div>
  );
};

export default CountryDistribution;
