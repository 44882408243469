import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as styles from "./styles.module.scss";

const ErrorModal = ({ show, data, handleClose, errorWarningId }) => {
  const errorWarning = data.find((item) => item.id === errorWarningId);

  const closeModal = () => {
    handleClose(false);
  };

  return (
    <Modal show={show} size={"md"} centered className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.modalHeader}>
          <div className={styles.values}>
            <span>{errorWarning?.type}</span>
            <span>{errorWarning?.error_page}</span>
            <span>{errorWarning?.pear_id}</span>
          </div>
        </div>

        <div className={styles.modalFooter}>
          <Button className={styles.cancel} onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
