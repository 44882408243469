import React, { useState, useEffect } from "react";
import SocialsDailyReachChart from "../../atoms/SocialsDailyReachChart";
import * as styles from "./styles.module.scss";
import { tooltipLabels } from "../../../constants/socialsData";
import Spinner from "../../atoms/Spinner";
import CalendarPicker from "../../atoms/CalendarPicker";
import Dropdown from "../../atoms/DropDown";
import { socials_data } from "../../../constants/socialsData";
import { socials } from "../../../constants/socials";

const SocialsDailyReach = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Facebook Daily Reach</h2>
        <div className={styles.legend}>
          <span>Daily Reach</span>
          <span>Avg Reach</span>
          <span>Impressions</span>
        </div>
        <div className={styles.dropDowns}>
          <Dropdown title={socials[0]["name"]} data={socials} />
          <CalendarPicker />
        </div>
      </div>
      {socials_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {socials_data.length > 0 && socials_data.map((i) => i !== undefined) && (
        <SocialsDailyReachChart
          data={socials_data}
          tooltipLabels={tooltipLabels}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default SocialsDailyReach;
