import {
  GET_CHROME_ENGAGEMENTS,
  GET_FIREFOX_ENGAGEMENTS,
  GET_OPERA_ENGAGEMENTS,
  GET_EDGE_ENGAGEMENTS,
  GET_SAFARI_ENGAGEMENTS,
} from "../../constants/actionTypes";

const initialState = {
  chromeEngagements: [],
  firefoxEngagements: [],
  operaEngagements: [],
  edgeEngagements: [],
  safariEngagements: [],
  errors: {},
};

export const chromeEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHROME_ENGAGEMENTS:
      return {
        ...state,
        chromeEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const firefoxEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FIREFOX_ENGAGEMENTS:
      return {
        ...state,
        firefoxEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const operaEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERA_ENGAGEMENTS:
      return {
        ...state,
        operaEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const edgeEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EDGE_ENGAGEMENTS:
      return {
        ...state,
        edgeEngagements: action.payload,
      };
    default:
      return state;
  }
};

export const safariEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAFARI_ENGAGEMENTS:
      return {
        ...state,
        safariEngagements: action.payload,
      };
    default:
      return state;
  }
};
