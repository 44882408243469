import React from "react";
import * as styles from "./styles.module.scss";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const COLORS = ["#0693DA", "#00A480", "#FFFF", "#EC7600"];

const PieChartWithPaddingAngle = ({ data }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={styles.customTooltip}>
          {payload.map((item) => {
            return (
              <div className={styles.inner} key={item["name"]}>
                <p className={styles.toolTipHeader}>
                  <span>{item["name"]}</span>
                </p>
                <p className={styles.toolTipText}>
                  <span style={{ color: item["payload"]["fill"] }}>
                    {item["value"]} %
                  </span>
                </p>
                <p className={styles.pointer}></p>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <PieChart width={178} height={178}>
      <Tooltip
        content={<CustomTooltip />}
        cursor={false}
        allowEscapeViewBox={{ x: true, y: true }}
        offset={6}
      />

      <Pie
        data={data}
        innerRadius={55}
        outerRadius={83}
        fill="#8884d8"
        paddingAngle={0.0}
        dataKey="value"
        stroke={{ width: 0.0 }}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartWithPaddingAngle;
