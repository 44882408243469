export const errors_warnings_table_data = [
  {
    id: 1,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 2,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 3,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 4,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 5,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 6,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 7,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 8,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 9,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 10,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 11,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 12,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 13,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 14,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 15,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 16,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 17,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 18,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 19,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 20,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 21,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 22,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 23,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 24,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 25,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 26,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 27,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 28,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 29,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 30,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 31,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 32,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 33,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 34,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 35,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 36,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 37,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 38,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 39,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 40,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 41,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 42,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 43,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 44,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 45,
    error_page: "Sharing presentation",
    display_name: "John Parker",
    method_name: "Share",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 46,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Error",
  },
  {
    id: 47,
    module: "Adjust settings",
    display_name: "Mark Villian",
    method_name: "Settings",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
  {
    id: 48,
    module: "Record video",
    display_name: "Ole Dojo",
    method_name: "Record",
    pear_id: "783-543 GGF",
    date: "09 - 11 - 2021",
    type: "Warning",
  },
];
