import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Navigation from "../../molecules/Navbar";
import NavigationMobile from "../../molecules/NavbarMobile";

const DefaultLayout = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      {isMobile ? <NavigationMobile /> : <Navigation />}
      {children}
    </Container>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
