export const age_demographics_data = [
  {
    age: "18 - 24",
    male: 45,
    female: 120,
    total: 165,
    total_percentage: 10,
    male_percentage: 4,
    female_percentage: 96,
  },
  {
    age: "25 - 34",
    male: 45,
    female: 125,
    total: 170,
    total_percentage: 11,
    male_percentage: 33,
    female_percentage: 67,
  },
  {
    age: "35 - 44",
    male: 45,
    female: 186,
    total: 231,
    total_percentage: 15,
    male_percentage: 33,
    female_percentage: 67,
  },
  {
    age: "45 - 54",
    male: 45,
    female: 164,
    total: 209,
    total_percentage: 13,
    male_percentage: 33,
    female_percentage: 67,
  },
  {
    age: "55 - 64",
    male: 45,
    female: 204,
    total: 249,
    total_percentage: 16,
    male_percentage: 33,
    female_percentage: 67,
  },
  {
    age: "65+",
    male: 45,
    female: 503,
    total: 548,
    total_percentage: 35,
    male_percentage: 33,
    female_percentage: 67,
  },
];

export const tooltipLabels = [
  {
    key: "total_percentage",
    label: "Total",
  },
  {
    key: "female_percentage",
    label: "Female",
  },
  {
    key: "male_percentage",
    label: "Male",
  },
];
