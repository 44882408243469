import React, { useEffect, useState } from "react";
import dropDownIcon from "../../../assets/images/icons/drop-down.svg";
import * as styles from "./styles.module.scss";

const DropDown = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setSelectedOption] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const isLarge = width >= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.select} onClick={toggling}>
        {title} <img src={dropDownIcon} alt="dropdown-icon" />
      </div>
      {isLarge && isOpen && (
        <div>
          <div className={styles.options}>
            {data.map((item, index) => (
              <p
                key={index}
                className={styles.item}
                onClick={() => onOptionClicked(item.name)}
                value={item["name"]}
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
