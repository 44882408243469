import React from "react";
import "regenerator-runtime/runtime";
import * as styles from "./styles.module.scss";
import caretUp from "../../../assets/images/icons/caret-up-fill.svg";
import { eventTypes } from "../../../constants/eventTypes";
import ProgressBar from "../../atoms/ProgressBar";
import { percentageCalculator } from "../../../helpers/percentageCalculator";

const EventsType = ({ page }) => {
  const totalEvents = 846;

  return (
    <div
      className={`${
        page == "wizrconnect"
          ? `${styles.wizrConnectContainer}`
          : `${styles.dashboardContainer}`
      }`}
    >
      <h2>Events Type</h2>
      <p>Summary of the distribution of grouped Usage</p>
      {eventTypes.map((eventType) => {
        let eventCount = eventType.count;
        if (eventCount !== null) {
          return (
            <div key={eventType.title} className={styles.content}>
              <h5>{eventType.title}</h5>
              <h5 className={styles.percentages}>
                {Math.round(percentageCalculator(eventCount, totalEvents))} %
                <img className={styles.caretUp} src={caretUp} alt="UpIcon" />
              </h5>
              <ProgressBar
                percentage={percentageCalculator(eventCount, totalEvents)}
              />
            </div>
          );
        }
      })}
    </div>
  );
};
export default EventsType;
