import { GET_NEW_AND_RETURNING_USERS } from "../../constants/actionTypes";

const initialState = {
  newAndReturningUsers: [],
  errors: {},
};

export const newAndReturningUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEW_AND_RETURNING_USERS:
      return {
        ...state,
        newAndReturningUsers: action.payload,
      };
    default:
      return state;
  }
};
