import {
  GET_SHOWROOM_EVENTS,
  GET_LIBRARY_EVENTS,
  GET_COVER_PAGE_EVENTS,
  GET_PRESENTATION_EVENTS,
} from "../../constants/actionTypes";

const initialState = {
  showroomEvents: [],
  libraryEvents: [],
  coverPageEvents: [],
  presentationEvents: [],
  errors: {},
};

export const libraryEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIBRARY_EVENTS:
      return {
        ...state,
        libraryEvents: action.payload,
      };
    default:
      return state;
  }
};

export const showroomEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOWROOM_EVENTS:
      return {
        ...state,
        showroomEvents: action.payload,
      };
    default:
      return state;
  }
};

export const coverPageEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COVER_PAGE_EVENTS:
      return {
        ...state,
        coverPageEvents: action.payload,
      };
    default:
      return state;
  }
};

export const presentationEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESENTATION_EVENTS:
      return {
        ...state,
        presentationEvents: action.payload,
      };
    default:
      return state;
  }
};
