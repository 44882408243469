import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import paginateTable from "../../../helpers/pagination";
import CountriesTableFooter from "../../atoms/CountriesTableFooter";
import searchIcon from "../../../assets/images/icons/search.png";
import upwardArrow from "../../../assets/images/icons/colored-upward-arrow.svg";

const CountriesDataTable = ({ data, rowsPerPage }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = paginateTable(data, page, rowsPerPage);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(false);
  };

  const expand = () => {
    setIsExpanded(true);
  };

  return (
    <div>
      {isExpanded && (
        <div className={styles.container}>
          <button className={styles.button} onClick={handleClick}>
            x
          </button>
          <table className={styles.tableOne}>
            <thead className={styles.tableRowHeader}>
              <tr>
                <th className={styles.tableHeader}>Countries</th>
                <th className={styles.tableHeader} id={styles.headerTwo}>
                  Users
                </th>
              </tr>
              <div className={styles.search}>
                <img src={searchIcon} alt="Search Icon" />
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Type country name"
                />
              </div>
            </thead>
          </table>
          <table className={styles.table}>
            <tbody>
              {slice.map((item) => (
                <tr className={styles.tableRowItems} key={item.country}>
                  <td className={styles.tableCell}>{item.country}</td>
                  <td className={styles.tableCellTwo}>{item.count} </td>
                  <td className={styles.tableCellTwo} id={styles.arrow}>
                    <img src={upwardArrow} alt="Upward arrow" />
                  </td>
                  <td className={styles.tableCellTwo} id={styles.percentage}>
                    {" "}
                    {item.percentage}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <CountriesTableFooter
            range={range}
            slice={slice}
            setPage={setPage}
            page={page}
          />
        </div>
      )}

      {!isExpanded && (
        <div className={styles.containerInset}>
          <table className={styles.tableOne}>
            <thead className={styles.tableRowHeader}>
              <tr>
                <th className={styles.tableHeader}>Countries</th>
                <th className={styles.tableHeader} id={styles.headerTwo}>
                  Users
                </th>
              </tr>
              <div className={styles.search}>
                <img src={searchIcon} alt="Search Icon" />
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Type country name"
                />
              </div>
            </thead>
          </table>
          <table className={styles.table}>
            <tbody>
              {slice.map((item) => (
                <tr className={styles.tableRowItems} key={item.country}>
                  <td className={styles.tableCell}>{item.country}</td>
                  <td className={styles.tableCellTwo}>{item.count} </td>
                  <td className={styles.tableCellTwo} id={styles.arrow}>
                    <img src={upwardArrow} alt="Upward arrow" />
                  </td>
                  <td className={styles.tableCellTwo} id={styles.percentage}>
                    {" "}
                    {item.percentage}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p onClick={expand}>See all</p>
        </div>
      )}
    </div>
  );
};

export default CountriesDataTable;
