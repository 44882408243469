import React from "react";
import HeaderLayout from "../components/organisms/HeaderLayout";
import ComponentsLayout from "../components/organisms/ComponentsLayout";
import PageNotice from "../components/atoms/PageNotice";
import Header from "../components/atoms/Header";

const Connect = () => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <main>
        <ComponentsLayout>
          <Header page={"/connect"} />
          <PageNotice />
        </ComponentsLayout>
      </main>
    </>
  );
};

export default Connect;
