import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import Table from "../../molecules/Table";
import { errors_warnings_table_data } from "../../../constants/errorsWarningsTableData";

const ErrorsWarningsTable = () => {
  const [data] = useState([...errors_warnings_table_data]);

  const tableHeaders = [
    "Page",
    "Name",
    "Method",
    "Pear ID",
    "Date",
    "Type",
    "Action",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Errors and warnings</h2>
      </div>
      <Table
        type="errors-warnings"
        tableHeaders={tableHeaders}
        data={data}
        rowsPerPage={7}
        check
      />
    </div>
  );
};

export default ErrorsWarningsTable;
