export const numeric_data = [
  {
    valueOne: "4k",
    descriptionOne: "Organic reach of page posts",
    valueTwo: "9k",
    descriptionTwo: "Total clicked views",
    valueThree: "10k",
    descriptionThree: "Video repeats",
  },
  {
    valueOne: "1k",
    descriptionOne: "Page Consumptions",
    valueTwo: "100k",
    descriptionTwo: "Page engaged users",
    valueThree: "6k",
    descriptionThree: "Likes",
  },
  {
    valueOne: "5k",
    descriptionOne: "Total organic views",
    valueTwo: "8k",
    descriptionTwo: "Total unique video views",
    valueThree: "10k",
    descriptionThree: "Unlikes",
  },
  {
    valueOne: "1k",
    descriptionOne: "Viral impressions",
    valueTwo: "4k",
    descriptionTwo: "Viral Reach",
    valueThree: "1k",
    descriptionThree: "Viral impressions of your posts",
  },
];
