import {
  GET_USERS,
  GET_SESSIONS,
  GET_DIRECT_TRAFFIC,
} from "../../constants/actionTypes";

const initialState = {
  users: [],
  sessions: [],
  directTraffic: [],
  errors: {},
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};

export const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      };
    default:
      return state;
  }
};

export const directTrafficReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECT_TRAFFIC:
      return {
        ...state,
        directTraffic: action.payload,
      };
    default:
      return state;
  }
};
