const today = new Date();
let currentMonth = today.getMonth() + 1;
let currentYear = new Date().getFullYear();
var month = currentMonth;
const months = [
  { 1: "Jan" },
  { 2: "Feb" },
  { 3: "Mar" },
  { 4: "Apr" },
  { 5: "May" },
  { 6: "June" },
  { 7: "July" },
  { 8: "Aug" },
  { 9: "Sept" },
  { 10: "Oct" },
  { 11: "Nov" },
  { 12: "Dec" },
];

const data = [];
export const generateMonthlyData = () => {
  for (let count = 0; count < 12; count++) {
    if (month > 0) {
      data.unshift({
        name: months[month - 1][month],
        new_users: 0,
        returning_users: 0,
        startDate: `${currentYear}-${getMonth(month)}-01`,
        endDate: `${currentYear}-${getMonth(month)}-${new Date(
          currentYear,
          month,
          0
        ).getDate()}`,
      });
      --month;
    } else {
      month = 12;
      --currentYear;
      data.unshift({
        name: months[month - 1][month],
        new_users: 0,
        returning_users: 0,
        startDate: `${currentYear}-${getMonth(month)}-01`,
        endDate: `${currentYear}-${getMonth(month)}-${new Date(
          currentYear,
          month,
          0
        ).getDate()}`,
      });
      --month;
    }
  }
  return data;
};

const getMonth = (month) => {
  return ("0" + month).slice(-2);
};

export const tooltipLabels = [
  {
    key: "percentage_all_users",
    label: "All users",
  },
  {
    key: "new_users_percentage",
    label: "New",
  },
  {
    key: "returning_users_percentage",
    label: "Returning",
  },
];

export const user_data = [
  {
    name: "Mon - Oct 20",
    new_users: 781,
    returning_users: 5000,
    new_users_percentage: 14,
    returning_users_percentage: 86,
    percentage_all_users: 36,
  },
  {
    name: "Tue - Oct 21",
    new_users: 4010,
    returning_users: 710,
    new_users_percentage: 85,
    returning_users_percentage: 15,
    percentage_all_users: 30,
  },
  {
    name: "Wed - Oct 22",
    new_users: 7701,
    returning_users: 8000,
    new_users_percentage: 49,
    returning_users_percentage: 51,
    percentage_all_users: 98,
  },
  {
    name: "Thu - Oct 23",
    new_users: 190,
    returning_users: 2701,
    new_users_percentage: 7,
    returning_users_percentage: 93,
    percentage_all_users: 18,
  },
  {
    name: "Fri - Oct 24",
    new_users: 5006,
    returning_users: 100,
    new_users_percentage: 98,
    returning_users_percentage: 2,
    percentage_all_users: 32,
  },
  {
    name: "Sat - Oct 25",
    new_users: 201,
    returning_users: 783,
    new_users_percentage: 20,
    returning_users_percentage: 80,
    percentage_all_users: 6,
  },
  {
    name: "Sun - Oct 26",
    new_users: 801,
    returning_users: 397,
    new_users_percentage: 67,
    returning_users_percentage: 33,
    percentage_all_users: 1,
  },
];
