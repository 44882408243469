import React, { useState, useEffect } from "react";
import NewUsersByChannelChart from "../../atoms/NewUsersByChannelChart";
import CalendarPicker from "../../atoms/CalendarPicker";
import * as styles from "./styles.module.scss";
import { tooltipLabels } from "../../../constants/newUsersChannelData";
import Spinner from "../../atoms/Spinner";
import { new_users_channel_data } from "../../../constants/newUsersChannelData";

const NewUsersByChannel = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>New users by channel group</h2>
        <div className={styles.legend}>
          <span>Direct</span>
          <span>Organic Search</span>
          <span>Referral</span>
        </div>
        <div className={styles.dropDowns}>
          <CalendarPicker />
        </div>
      </div>
      {new_users_channel_data.length === 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {new_users_channel_data.length > 0 &&
        new_users_channel_data.map((i) => i !== undefined) && (
          <NewUsersByChannelChart
            data={new_users_channel_data}
            tooltipLabels={tooltipLabels}
            isMobile={isMobile}
          />
        )}
    </div>
  );
};

export default NewUsersByChannel;
