import {
  GET_INDIVIDUALS,
  GET_ORGANISATIONS,
} from "../../constants/actionTypes";

const initialState = {
  individuals: [],
  organisations: [],
  errors: {},
};

export const individualsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDIVIDUALS:
      return {
        ...state,
        individuals: action.payload,
      };
    default:
      return state;
  }
};

export const organisationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
      };
    default:
      return state;
  }
};
