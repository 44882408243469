import React from "react";
import libraryLogo from "../../../assets/images/icons/icon-library-colored.svg";
import studioLogo from "../../../assets/images/icons/icon-studio-colored.svg";
import connectLogo from "../../../assets/images/icons/icon-connect-colored.svg";
import * as styles from "./styles.module.scss";

const ErrorWarningsSelector = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div id={styles.all}>All</div>
        <div>
          <span>
            <p>
              <img src={libraryLogo} alt="library logo" />
            </p>
            <p id={styles.text}>Library</p>
          </span>
        </div>
        <div>
          <span>
            <p>
              <img src={studioLogo} alt="studio logo" />
            </p>
            <p id={styles.text}>Studio</p>
          </span>
        </div>
        <div>
          <span>
            <p>
              <img src={connectLogo} alt="connect logo" />
            </p>
            <p id={styles.text}>Connect</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErrorWarningsSelector;
