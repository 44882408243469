// import { months } from "../constants/calendar";

// let today = new Date();
// let dayToday = new Date().getDate();
// let currentMonthName = months[today.getMonth()];
// let currentMonth = today.getMonth() + 1;
// let lastMonthName = months[currentMonth - 2];
// let lastMonth = today.getMonth();
// let currentYear = new Date().getFullYear();
// let lastDayOfLastMonth = new Date(currentYear, lastMonth, 0).getDate();

// const getLastMonthName = () => {
//   if (currentMonth === 1) {
//     lastMonthName = months[11];
//     return lastMonthName;
//   } else {
//     let lastMonthName = months[currentMonth - 2];
//     return lastMonthName;
//   }
// };

// const getMonth3MonthsAgo = () => {
//   if (currentMonth < 3) {
//     let month3MonthsAgo = months[12 - (currentMonth + 2)];
//     return month3MonthsAgo;
//   } else {
//     let month3MonthsAgo = months[currentMonth - 3];
//     return month3MonthsAgo;
//   }
// };

export const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const generateCalendarData = () => {
  const calendarOptions = [[], []];
  // Get the date 7 days ago.
  // var date7DaysAgo = new Date(today.getTime() - 60 * 60 * 24 * 7 * 1000);
  // var date7DaysAgo2 = new Date(date7DaysAgo);
  // let day = date7DaysAgo.getDay();
  // let diffToMonday = date7DaysAgo.getDate() - day + (day === 0 ? -6 : 1);

  //  Evaluate date for Monday and Sunday last week.
  // let lastMonday = new Date(date7DaysAgo.setDate(diffToMonday));
  // let lastSunday = new Date(date7DaysAgo2.setDate(diffToMonday + 6));

  // Evaluate months for last week's Monday and Sunday.
  // let monthOfLastMonday = months[lastMonday.getMonth()];
  // let monthOfLastSunday = months[lastSunday.getMonth()];

  calendarOptions[0].push(
    // `Today (${currentMonthName} ${dayToday})`
    `Today`
  );
  calendarOptions[0].push(
    // `Last week (${monthOfLastMonday} ${lastMonday.getDate()} - ${monthOfLastSunday} ${lastSunday.getDate()})`
    `7 days ago`
  );
  calendarOptions[0].push(
    // `Last month (${getLastMonthName()}1 - ${getLastMonthName()} ${lastDayOfLastMonth})`
    `30 days ago`
  );
  calendarOptions[0].push(
    // `Last 3 months (${getMonth3MonthsAgo()} - ${currentMonthName})`
    `90 days ago`
  );
  calendarOptions[0].push("Custom Range");

  // Shorter Date
  calendarOptions[1].push(
    // `(${currentMonthName} ${dayToday})`
    `Today`
  );
  calendarOptions[1].push(
    // `(${monthOfLastMonday} ${lastMonday.getDate()} - ${monthOfLastSunday} ${lastSunday.getDate()})`
    `7 days ago`
  );
  calendarOptions[1].push(
    // `(${getLastMonthName()}1 - ${getLastMonthName()} ${lastDayOfLastMonth})`
    `30 days ago`
  );
  calendarOptions[1].push(
    // `(${getMonth3MonthsAgo()} - ${currentMonthName})`
    `90 days ago`
  );
  calendarOptions[1].push("Custom Range");

  return calendarOptions;
};
