export const visitedPagesData = [
  {
    id: 1,
    module: "Homepage",
    user_name: "4.043",
    email_address: "98%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#1",
  },
  {
    id: 2,
    module: "Modules",
    user_name: "843",
    email_address: "58%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#2",
  },
  {
    id: 3,
    module: "Magazine",
    user_name: "18.043",
    email_address: "100%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#3",
  },
  {
    id: 4,
    module: "About us",
    user_name: "3.043",
    email_address: "60%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#4",
  },
  {
    id: 5,
    module: "Connect",
    user_name: "40",
    email_address: "3.8%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#5",
  },
  {
    id: 6,
    module: "Write us",
    user_name: "1.942",
    email_address: "40%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#6",
  },
  {
    id: 7,
    module: "10x",
    user_name: "1.942",
    email_address: "40%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#7",
  },
  {
    id: 8,
    module: "About us",
    user_name: "3.043",
    email_address: "60%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#8",
  },
  {
    id: 9,
    module: "Connect",
    user_name: "40",
    email_address: "3.8%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#9",
  },
  {
    id: 10,
    module: "Write us",
    user_name: "1.942",
    email_address: "40%",
    method_name: "Lorem ipsum dolor sit amet, consctetur adipiscing elit",
    visits_per_user: "14",
    average_engagement_time: "5 sec",
    peer_id: "#10",
  },
];

export const websiteTrafficData = [
  {
    id: 1,
    module: "Homepage",
    user_name: "4.043",
    competiton: "High",
    peer_id: "#1",
  },
  {
    id: 2,
    module: "Modules",
    user_name: "843",
    competiton: "High",
    peer_id: "#2",
  },
  {
    id: 3,
    module: "Magazine",
    user_name: "18.043",
    competiton: "High",
    peer_id: "#3",
  },
  {
    id: 4,
    module: "About us",
    user_name: "3.043",
    competiton: "High",
    peer_id: "#4",
  },
  {
    id: 5,
    module: "Connect",
    user_name: "40",
    competiton: "High",
    peer_id: "#5",
  },
  {
    id: 6,
    module: "Write us",
    user_name: "1.942",
    competiton: "High",
    peer_id: "#6",
  },
  {
    id: 7,
    module: "10x",
    user_name: "1.942",
    competiton: "High",
    peer_id: "#7",
  },
  {
    id: 8,
    module: "About us",
    user_name: "3.043",
    competiton: "High",
    peer_id: "#8",
  },
  {
    id: 9,
    module: "Connect",
    user_name: "40",
    competiton: "High",
    peer_id: "#9",
  },
  {
    id: 10,
    module: "Write us",
    user_name: "1.942",
    competiton: "High",
    peer_id: "#10",
  },
];

export const keywords = [
  {
    id: 1,
    module: "WiZR",
    user_name: "4.043",
  },
  {
    id: 2,
    module: "Excellent",
    user_name: "7.043",
  },
  {
    id: 3,
    module: "Shining",
    user_name: "5.043",
  },
  {
    id: 4,
    module: "Master",
    user_name: "2.043",
  },
  {
    id: 5,
    module: "Magically",
    user_name: "4.043",
  },
  {
    id: 6,
    module: "Remotely",
    user_name: "2.043",
  },
  {
    id: 7,
    module: "Empower",
    user_name: "1.043",
  },
  {
    id: 8,
    module: "Connection",
    user_name: "8.043",
  },
  {
    id: 9,
    module: "Productivity",
    user_name: "3.043",
  },
  {
    id: 10,
    module: "Future",
    user_name: "10.043",
  },
  {
    id: 11,
    module: "Simple",
    user_name: "4.043",
  },
  {
    id: 12,
    module: "Elegant",
    user_name: "5.043",
  },
];
