export const tooltipLabels = [
  {
    key: "shared_presentations_percentage",
    label: "Shared presentations",
  },
  {
    key: "all_created_presentations_percentage",
    label: "All Created presentations",
  },
  {
    key: "new_presentations_percentage",
    label: "New presentations",
  },
];

export const presentations_data = [
  {
    name: "Mon - Oct 20",
    all_created_presentations: 500,
    new_presentations: 397,
    shared_presentations: 801,
    all_created_presentations_percentage: 33,
    new_presentations_percentage: 20,
    shared_presentations_percentage: 47,
  },
  {
    name: "Tue - Oct 21",
    all_created_presentations: 150,
    new_presentations: 710,
    shared_presentations: 4010,
    all_created_presentations_percentage: 3,
    new_presentations_percentage: 15,
    shared_presentations_percentage: 82,
  },
  {
    name: "Wed - Oct 22",
    all_created_presentations: 190,
    new_presentations: 850,
    shared_presentations: 2701,
    all_created_presentations_percentage: 5,
    new_presentations_percentage: 23,
    shared_presentations_percentage: 72,
  },
  {
    name: "Thu - Oct 23",
    all_created_presentations: 201,
    new_presentations: 783,
    shared_presentations: 50,
    all_created_presentations_percentage: 19,
    new_presentations_percentage: 75,
    shared_presentations_percentage: 4,
  },
  {
    name: "Fri - Oct 24",
    all_created_presentations: 320,
    new_presentations: 100,
    shared_presentations: 5006,
    all_created_presentations_percentage: 6,
    new_presentations_percentage: 2,
    shared_presentations_percentage: 92,
  },
  {
    name: "Sat - Oct 25",
    all_created_presentations: 650,
    new_presentations: 8000,
    shared_presentations: 7701,
    all_created_presentations_percentage: 4,
    new_presentations_percentage: 49,
    shared_presentations_percentage: 47,
  },
  {
    name: "Sun - Oct 26",
    all_created_presentations: 781,
    new_presentations: 5000,
    shared_presentations: 770,
    all_created_presentations_percentage: 12,
    new_presentations_percentage: 76,
    shared_presentations_percentage: 12,
  },
];
